<template>
  <v-container fluid :data-test-id="'container-' + index">
    <v-autocomplete
      v-model="presetFilter[0]"
      :items="getFilters"
      item-text="title"
      item-value="value"
      dense
      :label="label"
      @change="valueChanged"
      :clearable="true"
      @click:clear="clearData"
      :data-test-id="'date-list-' + index"
    >
    </v-autocomplete>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    items: [
      "lastOneDay",
      "lastOneWeek",
      "lastOneMonth",
      "lastThreeMonth",
      "lastSixMonth",
      "lastOneYear",
      "lastFiveYears",
      "lastTenYears",
      "sinceToday",
      "sinceThisWeek",
      "sinceThisMonth",
      "sinceThisYear",
      "nextOneMonth",
      "nextThreeMonth",
      "nextSixMonth",
      "nextOneYear",
      "lastOneCalendarMonth",
      "lastThreeCalendarMonth",
      "lastSixCalendarMonth",
      "lastOneCalendarYear",
      "firstQuarter",
      "secondQuarter",
      "thirdQuarter",
      "fourthQuarter",
    ],
    value: null
  }),
  props: ["filter", "index", "disabled", "label"],
  computed: {
    presetFilter() {
      return this.filter;
    },
    getFilters() {
      const computedFilters = this.items.map(item => {
        return { title: this.$t("filter." + item), value: item };
      });
      return computedFilters;
    }
  },
  methods: {
    valueChanged() {
      this.$emit("value-changed", {
        data: this.presetFilter,
        index: this.index
      });
    },
    emit(event) {
      this.$eventBus.$emit(event);
    },
    clearData() {
      this.$emit("value-changed", {
        data: [],
        index: this.index
      });
    }
  }
};
</script>
<style scoped>
.container {
  padding-left: 0px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 350px;
  min-width: 250px;
  z-index: 99999;
}
</style>
