export default {
  common: {
    add: "Add",
    save: "Save",
    cancel: "Cancel",
    continue: "Continue",
    required: "* Required",
    close: "Close",
    loading: "Loading",
    reset: "Reset",
    clear: "Clear",
    next: "Next",
    back: "Back",
    finish: "Finish"
  },
  dialog: {
    yes: "Yes",
    no: "No",
    deleteCategoryWarning: "Category {0} will be deleted with its contents. Continue?",
    deletePanelWarning: "Panel {0} will be deleted. Continue?",
    deleteReportWarning: "Report {0} will be deleted. Continue?",
    moveWarning1: "{0} will be moved to category {1}. Continue?",
    moveWarning2: "{0} will be moved to home page. Continue?",
    deleteProjectWarning: "Project {0} will be deleted. Continue?",
    saveProjectWarning:
      "Selected user has priviledge on the project. Continue?",
    deleteCatalogWarning: "Catalog {0} will be deleted. Continue?",
    savingDataWarning: "Dou you want to save the changes?",
    deleteModelWarning:
      "Model {0} will be deleted. Continue?", 
    savingGroupDataWarning: "Do you want to save group data?", 
    deleteRightWarning:
      "Role {0} will be deleted. Continue?",  
    deleteUserGroupRightWarning:
      "The right of user/group {0} will be deleted. Continue?",
    deleteSubscriptionWarning:  "Subscription {0} will be deleted. Continue?", 
    activateSubscriptionWarning:
      "Notifications of subscription {0} will open. Dou you want to continue?",
    deactivateSubscriptionWarning:
      "Notifications of subscription {0} will close. Dou you want to continue?",
    savingProjectDataWarning: "Dou you want to save the changes?",
    deleteScheduleWarning: "The schedule {0} will be deleted. Continue?",
    deleteDomainWarning: "The domain {0} will be deleted. Continue?",
  },
  error: {
    catalogExistsWarning: "Another catalog already exists with this name",
    completeFiltersWarning: "Complete filters please",
    measureAlreadyExists: 'There are multiple measures with same name!',
    dimensionAlreadyExists: 'There are multiple dimensions with same name!',
    dimensionOrMeasureAlreadyExists: 'There are multiple dimensions or measures with same name!',
    modelAlreadyExistsWarning: 'Another model already exists with this name!',
    completeModelWarning: 'Please complete the model!',
    passwordChangedEror: "An error occurred: ",
    invalidPassword: "You entered wrong password. Password must be at least 8 characters long, includes at least one symbol, one lowercase character and one uppercase character.",
    errorMessage: "Error occurred!",
    errorSSOLogoutMessage: "SSO Logout is unsuccessful. Please try again.",
    expansionInvalidMessage: "Expansion is invalid! The data could not be found in the columns to be matched. Try again after making the necessary corrections at the database level.",
    categoryExistsWarning: "Another category already exists with this name",
    panelExistsWarning: "Another panel already exists with this name",
    groupExistsWarning: "Another model already exists with this name",
    userGroupSelectWarning: "Select user!",
    deactivateProjectManager: " is a project manager of many projects.Please change the project managers of these projects first.",
    dublicateDimensionName: "Another measure/dimension already exists with this name!",
    badSqlStatement: "Wrong SQL statement!",
    projectDeleteWarning1: "There is an authorization definition under the project you want to delete. Please delete the permissions first!",
    projectDeleteWarning2: "There is a category/catalog under the project you want to delete. This project cannot be deleted!",
    scheduleExistsWarning: "Another schedule already exists with this name!",
    sendMailError: "Activation mail cannot be sent! An error occurred.",
    alreadyDomainContent: "Another domain already exists with this name!",
    userExistsError: "There is already a user exists with the given username or email address!",
    timelineExistsError: "There is already a timeline exists with the given name",
    domainAlreadyExistsWarning: "Domains with the same name cannot be saved!",
    sendRawDataEmailError: "An error occurred while sending email!",
    subscriptionAlreadyExistsWarning: "There is already a subscription exists with the given name",
    groupExistsWarning: "There is already a group exists with the given name"
  },
  login: {
    username: "Username",
    password: "Password",
    usernameOrPasswordInvalid: "Invalid username or password",
    userUnauthorized: "Unauthorized user",
    button: "Login",
    egovernmentbutton: "E-government Login",
    openId: "Türksat OpenId",
    forgot: "Forgot Password",
    updatePassword: "Update Password",
    invalidDatabaseConnection:
      "Database connection failed. Check the connection data.",
    sessionTimeOut: "Oturum Süresi Dolmuştur!",
    userNotActive: "User is passive. Please contact your system administrator.",
    userNotFound: "User not found!",
    userActivated: "User is already activated!",
    tokenFail: "User activation fail!",
    tokenExpired: "User activation time out. Please create a new user account again!",
    initialPasswordWrong: "Initial password is wrong. Please try again!",
    userInitialized: "User is not active yet. You must activate your user by clicking the link in your e-mail."
  
 },
 forgot: {
  title: 'Forgot Password?',
  subtitle: 'Enter your account email address and we will send you a link to reset your password.',
  email: 'Email',
  button: 'Request Password Reset',
  backtosign: 'Back to Sign In'
},
  userProfile: {
    userInformation: "User Information",
    personalInformation: "Personal Informations",
    changePassword: "Change Password",
    myContents: "My Contents",
    tabSubscription: "My Subscriptions",
    name: "Name",
    surname: "Surname",
    username: "User Name",
    email: "E-mail",
    ldapUserName: "LDAP User Name",
    union: "Union",
    title: "Title",
    editAvatar: "Edit Avatar",
    currentPassword: "Current Password",
    newPassword: "New Password",
    confirmNewPassword: "New Password ( Confirm )",
    type: "Type",
    contentName: "Content Name",
    role: "Role",
    location: "Location",
    passwordRules: {
      minCharacters:
        "The password must be a minimum of 8 characters and contain upper and lower case letters and numbers.",
      mismatch: "Password does not match",
      invalidPassword: "Invalid Password",
      enterDifferentPassword: "Enter a different password"
    },
    contents: {
      type: "Type",
      contentName: "Content Name",
      role: "Role",
      location: "Location"
    },
    subscription: {
      name: "Subscription Name",
      projectName: "Project Name",
      panelName: "Panel Name",
      period: "Period",
      creator: "Creator",
      subscType: "Subscription Type",
      status: "Status",
      sendStatus: "Notification"
    }
  },
  success: {
    successfullyPasswordChanged: "Your password has been changed successfully",
    modelUpdated: "Model is updated successfully!",
    userDataUpdated: "User data updated!",
    userGroupsUpdated: "User groups updated!",
    validSqlStatement: "Valid SQL Statement",
    inactivateUserStatus: "User disabled.",
    activateUserStatus: "User activated.",
    passwordChanged: "Password is changed successfully!",
    inactivateGroupStatus: "Group disabled.",
    activateGroupStatus: "Group activated.",
    updating: "Updated.",
    newGroupSuccess: "New group inserted!",
    inserted: "Inserted!",
    deleted: "Deleted!",
    groupUsersUpdated: "Group users updated!",
    inactivateSubsStatus: "Subscription disabled.",
    activateSubsStatus: "Subscription activated.",
    rightInserted: "Right inserted.",
    rightDeleted: "Right deleted.",
    sendMailSuccess: "Activation mail sent!",
    sendMailUserSuccess: "Activation e-mail has been sent to your e-mail address. Please check your inbox/spam box.",
    sendFileSuccess: "The file is sent to your e-mail address!"
  },
  dashboard: {
    categories: "Categories",
    panels: "Panels",
    reports: "Reports",
    newCategory: "New Category",
    newPanel: "New Panel",
    newReport: "New Report",
    update: "Update",
    delete: "Delete",
    subscribe: "New Subscription",
    showSubscription: "Show Subscription"
  },
  project: {
    projects: "Projects",
    newProject: "New Project",
    noProjectInfo: "There are no projects that you are authorized to view. Please contact your System Administrator!",
    noContentInfo: "There is no content to display. Please contact your system administrator!",
    dublicateProjectName: "There is a project with the same name!"
  },
  menu: {
    search: "Search",
    logout: "Logout",
    profile: "Profile",
    home: "Home",
    projects: "Projects",
    general: "General",
    management: "MANAGEMENT",
    system: "SYSTEM MANAGEMENT",
    openIdManagement: "OpenID Configuration",
    auditLogPage: "Audit Log Page",
    dataSettings: "Data Download Settings",
    title: "Data Visualization Platform",
    data: "DATA",
    catalogs: "Catalogs",
    userManagement: "User Management",
    groupManagement: "Group Management",
    projectCategoryAuth: "Project&Category Authorization",
    subscriptionManagement: "Subscription Management",
    scheduleManagement: "Schedule Management",
    projectMembers: "Project Members",
    authorization: "Authorization",
    about: "About",
    noResultsFound: "No results found!"
  },
  categoryCreator: {
    dialogTitleUpdate: "Update Category",
    dialogTitleCreate: "New Category",
    title: "Title",
    description: "Description",
    folderColor: "Folder Color"
  },
  panelCreator: {
    dialogTitleUpdate: "Update Panel",
    dialogTitleCreate: "New Panel",
    title: "Title",
    description: "Description",
    link: "Link",
    permissionGroups: "Permission Groups",
    refreshTime: "Refresh Period",
    timeType: "Time Type",
    theme: "Theme",
    backgroundColor: "Background Color",
    dark: "Dark",
    light: "Light",
    darkSequential: "Dark Sequential",
    darkPastel: "Dark Pastel",
    lightSequential: "Light Sequential",
    lightPastel: "Light Pastel",
    second: "Second",
    minute: "Minute",
    hour: "Hour",
    maxRefreshTime: "Refresh time must be between 1-100!",
    panelVisibility: "Panel Visibility"
  },
  reportCreator: {
    dialogTitleUpdate: "Update Report",
    dialogTitleCreate: "New Report",
    title: "Title",
    description: "Description",
    link: "Link",
    permissionGroups: "Permission Groups",
    refreshTime: "Refresh Period"
  },
  projectCreator: {
    dialogTitleCreate: "New Project",
    dialogTitleView: "Project Title",
    dialogTitleUpdate: "Update Project",
    projectInfo: "Project Information",
    dataSourceSelection: "Datasource Selection",
    dataSourceSetting: "Datasource Setting",
    projectName: "Project Name",
    projectDefinition: "Project Definition",
    projectAvatar: "Project Avatar",
    projectManager: "Project Manager",
    title: "Title",
    description: "Description",
    projectIcon: "Project Icon",
    dataSourceType: "Datasource Type ",
    host: "Host",
    port: "Port",
    databaseName: "Database",
    userName: "Username",
    password: "Password",
    datasources: "Datasources",
    datasourceName: "Datasource Name",
    datasourceDescription: "Description",
    completeDataWarning: "Fill in the required fields!",
    deleteAvatarTooltip: "Delete Avatar",
    viewTooltip: "View",
    updateTooltip: "Update",
    deleteTooltip: "Delete",
    infoTooltip: "Info",
    projectNameCharacterSize: "Project name must be maximum 128 characters!"
  },
  widget: {
    delete: "Delete",
    edit: "Update",
    previousChart: "Previous Chart",
    nextChart: "Next Chart",
    sortByLabel: "Sort by label",
    sortByMeasure: "Sort by measure",
    changeDateMode: "Change date format",
    copyToClipboard: "Copy to clipboard",
    saveAsImage: "Save as image",
    saveSummaryData: "Save Summary Data",
    saveRawData: "Save Raw Data",
    fullScreen: "Fullscreen",
    fullScreenCompress: "Original Size",
    setAsCover: "Set as cover",
    saveAsTemplate: "Save as Template",
    tag: "Tag",
    description: "Description",
    filters: "Filters",
    noDataInfo: "No Data"
  },
  tagCreator: {
    dialogTitleCreate: "New Template",
    tag: "Template Name"
  },
  infoCreator: {
    dialogTitleCreate: "New Description",
    description: "Description"
  },
  widgetToolbar: {
    widgetCreate: "New Widget",
    filters: "Filters"
  },
  toolbar: {
    notifications: "My Notifications",
    delete: "Delete"
  },
  filter: {
    date: "Date",
    "date-range": "Date Range",
    "multi-select": "Multi Select",
    "single-select": "Single Select",
    "preset-date": "Default Date",
    lastOneHour: "Last Hour",
    lastOneDay: "Last Day",
    lastOneWeek: "Last Week",
    lastOneMonth: "Last Month",
    lastThreeMonth: "Last 3 Months",
    lastSixMonth: "Last 6 Months",
    lastOneYear: "Last Year",
    nextOneMonth: "Next Month",
    nextThreeMonth: "Next 3 Months",
    nextSixMonth: "Next 6 Months",
    nextOneYear: "Next Year",
    sinceToday: "Today",
    sinceThisWeek: "This Week",
    sinceThisMonth: "This Month",
    sinceThisYear: "This Year",
    lastFiveYears: "Last 5 Years",
    lastTenYears: "Last 10 Years",
    lastOneCalendarMonth: "Last 1 Calendar Month",
    lastThreeCalendarMonth: "Last 3 Calendar Month",
    lastSixCalendarMonth: "Last 6 Calendar Month",
    lastOneCalendarYear: "Last 1 Calendar Year",
    firstQuarter: "First Quarter",
    secondQuarter: "Second Quarter",
    thirdQuarter: "Third Quarter",
    fourthQuarter: "Fourth Quarter",
  },
  filterSetting: {
    dialogTitle: "Filter Setting",
    addFilter: "New Filter",
    deleteFilter: "Delete Filter",
    type: "Type",
    label: "Label",
    filterLabelCharacterSize : "Max. 20 characters",
    "model/table": "Model / Table",
    "dimension/column": "Dimension / Column",
    defaultValues: "Default Values",
    date: "Date",
    "date-range": "Date Range",
    "multi-select": "Multi Select",
    "single-select": "Single Select",
    "preset-date": "Default Date"
  },
  widgetCreator: {
    templateSelection: "Template Selection",
    chartTypeSelection: "Chart Type Selection",
    dataOptionsSelection: "Data Options Selection",
    visualOptionsSelection: "Visual Options Selection",
    allCharts: "ALL",
    graphicCharts: "GRAPHICS",
    cardCharts: "CARDS",
    mapCharts: "MAPS",
    warning: "* Please select chart type!"
  },
  dataOptionsTab: {
    catalog: "Catalog",
    model: "Model",
    tableColumns: "Table Columns",
    tableColumnsOrder: "Table Column Order",
    valueField: "Value Field",
    valueFieldLine: "2nd Value Field",
    labelField: "Label Field",
    parentRegion: "Parent Region",
    fromLabelField: "From Label Field",
    toLabelField: "To Label Field",
    groupField: "Group Field",
    fact: "Fact",
    label: "Label",
    dimensions: "Dimensions",
    measures: "Measures",
    measure: "Measure",
    columns: "Fields",
    models: "Models",
    imageField: "Image",
    textField: "Text",
    mapType: "Map Type",
    routeType: "Route Type",
    latitudeField: "Latitude Field",
    longitudeField: "Longitude Field",
    fromLongitudeField: "From Longitude Field",
    fromLatitudeField: "From Latitude Field",
    toLongitudeField: "To Longitude Field",
    toLatitudeField: "To Latitude Field",
    targetField: "Target Field",
    targetValue: "Target Value",
    maxValue: "Maximum Value",
    maxValuePercentage: "Maximum Value Percentage",
    geoDataFormat: "Geographic Data Format",
    geoData: "Geographic Data",
    link: "Link",
    europe: "Europe Map",
    districts: "Turkey District Map",
    cities: "Turkey City Map",
    unions: "Cooperative Unions Map",
    atlasMap: "General Directorate of Maps",
    openStreet: "Open Street",
    limit: "Data Limit",
    protectedFields: "Protected Fields",
    dateMode: "Date Mode",
    operator: "Operator",
    value: "Value",
    filterPanelTitle: "Filters",
    geoJSON: "GeoJSON",
    WKT: "WKT",
    warning: "* Please fill necessary fields!",
    minLimitWarning: "Enter minimum 1 and maximum 999.999.999!"
  },
  visualOptionsTab: {
    basicOptions: "Basic Options",
    backgroundColor: "Background Color",
    backgroundColorOpacity: "Background Opacity",
    axisPointerType: "Axis Pointer Type",
    shadow: "Shadow",
    line: "Line",
    cross: "Cross",
    isStacked: "Stacked Bar",
    colors: "Colors",
    color: "Color",
    titleOptions: "Title Options",
    alignment: "Alignment",
    title: "Title",
    topTitle: "Top Title",
    bottomTitle: "Bottom Title",
    show: "Show",
    link: "Link",
    textColor: "Text Color",
    titleColor: "Title Color",
    subTitleColor: "Subtitle Color",
    left: "Left",
    right: "Right",
    top: "Top",
    bottom: "Bottom",
    subtitle: "Subtitle",
    autoSubtext: "Auto Subtext",
    fontFamily: "Font Family",
    fontSize: "Font Size",
    fontWeight: "Font Weight",
    fontStyle: "Font Style",
    legendOptions: "Legend Options",
    showLegends: "Show Legends",
    legendType: "Legend Type",
    legendColor: "Legend Colors",
    plain: "Plain",
    scroll: "Scroll",
    orient: "Orient",
    vertical: "Vertical",
    horizontal: "Horizontal",
    visualMap: "Visual Map",
    calculable: "Calculable",
    formatter: "Formatter",
    width: "Width",
    height: "Height",
    tooltip: "Tooltip",
    item: "Item",
    axis: "Axis",
    confine: "Confine",
    trigger: "Trigger",
    borderOptions: "Border Options",
    solid: "Solid",
    dotted: "Dotted",
    dashed: "Dashed",
    double: "Double",
    groove: "Groove",
    ridge: "Ridge",
    borderType: "Border Type",
    borderColor: "Border Color",
    borderRadius: "Border Radius",
    borderWidth: "Border Width",
    emphasisOptions: "Emphasis Options",
    filledColor: "Filled Color",
    shadowColor: "Shadow Color",
    shadowBlur: "Shadow Blur",
    sizeOptions: "Size Options",
    xAxisOptions: "X Axis Options",
    showXAxis: "Show X Axis",
    axisType: "Axis Type",
    category: "Label",
    value: "Value",
    automaticScale: "Automatic Scale",
    axisLine: "Axis Line",
    axisLineColor: "Axis Line Color",
    axisLineType: "Axis Line Type",
    axisTick: "Axis Tick",
    axisTickColor: "Axis Tick Color",
    axisTickType: "Axis Tick Type",
    splitLine: "Split Line",
    splitLineColor: "Split Line Color",
    splitLineType: "Split Line Type",
    axisLabel: "Axis Label",
    axisLabelColor: "Axis Label Color",
    rotate: "Rotate",
    labelLength: "Character Length",
    yAxisOptions: "Y Axis Options",
    showYAxis: "Show Y Axis",
    showSumLine: "Show Sum Line",
    yAxis2: "2nd Y Axis",
    equalAxis: "Equal Axis",
    axisFormatter: "Axis Formatter",
    tooltipFormatter: "Tooltip Formatter",
    axisLineShow: "Show Axis Line",
    axisLineWidth: "Axis Line Width",
    axisTickShow: "Axis Tick Show",
    splitLineShow: "Show Split Line",
    axisTickWidth: "Axis Tick Width",
    splitLineWidth: "Split Line Width",
    axisLabelShow: "Show Axis Label",
    pieOptions: "Pie Options",
    outsideRadius: "Outside Radius",
    insideRadius: "Inside Radius",
    centerX: "Center X",
    centerY: "Center Y",
    showLabels: "Show Labels",
    showPercentLabels: "Show Percent Labels",
    radialOptions: "Radial Options",
    brightnessBlur: "Brightness Blur",
    brightnessColor: "Brightness Color",
    showPercentage: "Show Percentage",
    labelColor: "Label Color",
    radialColor: "Radial Color",
    showLabel: "Show Label",
    innerPieOptions: "Inner Pie Options",
    outerPieOptions: "Outer Pie Options",
    geoOptions: "Geo Options",
    roam: "Roam",
    multiSelection: "Multi Selection",
    routeColor: "Route Color",
    routeWidth: "Route Width",
    showOnHover: "Show On Hover",
    alwaysShow: "Always Show",
    labelOptions: "Label Options",
    areaColor: "Area Color",
    mapStyle: "Map Style",
    weight: "Weight",
    opacity: "Opacity",
    fillColor: "Fill Color",
    textAlign: "Text Align",
    valueStyle: "Value Style",
    alignContentVertical: "Align Vertical",
    alignContentHorizontal: "Align Horizontal",
    textStyle: "Text Style",
    iconStyle: "Icon Style",
    iconColor: "Icon Color",
    iconSize: "Icon Size",
    subtext: "Sub Text",
    position: "Position",
    topLabelStyle: "Top Label Style",
    bottomLabelStyle: "Bottom Label Style",
    tableChartOptions: "Table Chart Options",
    showTableHeader: "Show Table Header",
    headerDirection: "Header Direction",
    tableView: "Table View",
    scrollTable: "Scroll",
    pagination: "Paginate",
    resizeCell: "Resize Cell",
    resizeContent: "Adjust based on content",
    resizeEqual: "Resize columns evenly",
    customize: "Customize Column Width",
    customizeColumnInfo: "You can specify the column width in the 'Table Fields Settings' section by giving the width ratio for each column.",
    showColumnSum: "Show Column Total",
    showValues: "Show Values"
  },
  tableChartVisualOptions: {
    visualOptions: "Table Fields Settings",
    columnTitle: "Title",
    columnView: "Column View",
    text: "Text",
    number: "Numeric",
    money: "Monetary",
    star: "Star",
    progress: "Progress Bar",
    date: "Date",
    tickCross: "Tick/Cross",
    textColor: "Text Color",
    filledColor: "Fill Color",
    progressColor: "Progress Bar Color",
    cellAlignment: "Cell Alignment",
    headerAlignment: "Header Alignment",
    numberFormat: "Number Format",
    currencyUnit: "Currency Unit",
    dateFormat: "Date Format",
    startMaxValue: "Maximum Value",
    starCount: "Number of Stars",
    minStarCountWarning: "Enter at least 2!",
    maxStarCountWarning: "Enter a maximum of 10!",
    columnWidthRatio: "Width Ratio (1-5)",
    minRatioWarning: "Enter at least 1!",
    maxRatioWarning: "Enter a maximum of 5!"
  },
  catalog: {
    catalogs: "Catalogs",
    name: "Catalog Name",
    description: "Description",
    dialogTitleUpdate: "Edit Catalog",
    dialogTitleCreate: "Create New Catalog",
    newCatalog: "Create Catalog",
    dsTitle: "{0} Data Source Information",
    dsType: "Data Source Type : ",
    dsHostName: "Hostname : ",
    dsPort: "Port : ",
    dsDbName: "Database Name : "
  },
  model: {
    description: "Description",
    newModel: "New Model",
    models: "Models",
    model: "Model",
    modelName: "Model Name",
    schema: "Schema",
    table: "Table",
    content: "Content Using the Model",
    contentType: "Type",
    contentName: "Content Name",
    contentPath: "Path",
    contentDialogTitle: "Content Using Model {0}",
    contentCardTitle: "Content Using the Model",
    copySql: "Copy SQL",
    copySqlInfo: "SQL is copied!"
  },
  modelCreator: {
    tableSelection: "Table Selection",
    expansionSelection: "Expansion",
    measureDimensionSelection: "Measures/Dimensions",
    modelComplete: "Complete Model",
    dataPreview: "Data Preview",
    previewTooltip: "Only 20 records are shown for data preview.",
    fields: "Fields",
    fieldName: "Field Name",
    fieldType: "Field Type",
    fieldSelection: "Field Selection",
    expansion: "EXPANSION",
    schema: "Schema",
    table: "Table",
    expansionColumn: "Field to Expand",
    addedColumns: "Added Columns",
    create: "Create",
    measures: "Measures",
    dimensions: "Dimensions",
    measure: "Measure",
    dimension: "Dimension",
    function: "FUNCTION",
    label: "LABEL",
    sum: "SUM",
    average: "Average",
    maximum: "Maksimum",
    minimum: "Minimum",
    median: "Median",
    standardDeviation: "Standard Deviation",
    variance: "Variance",
    count: "Count",
    distinctCount: "Distinct Count",
    reload: "Reload",
    modelName: "Model Name",
    definition: "Definition",
    operation: "Operation",
    convertToDimension: "Convert to Dimension",
    convertToMeasure: "Convert to Measure",
    tableSelectionLabel: "TABLE SELECTION",
    sqlEditor: "SQL EDITOR",
    sqlField: "Sql Editor",
    sqlCheckButton: "Check SQL",
    max50Characters: "Maximum 50 characters"
  },
  editModel: {
    updateModel: "Edit Model",
    copyModel: "Copy Model",
    deleteModel: "Delete Model",
    fieldSelection: "Field Selection",
    fieldName: "Field Name",
    fieldLabel: "Label",
    function: "Function",
    operations: "Operations",
    expansion: "EXPANSION",
    schema: "Schema",
    table: "Table",
    expansionColumn: "Expansion Column",
    addedColumns: "Added Columns",
    create: "Create",
    formula: "Formula",
    convertToDimension: "Convert to Dimension",
    convertToMeasure: "Convert to Measure",
    addFormula: "Add Formula",
    formulaDialogTitle: "New Measure",
    measures: "Measures",
    dimensions: "Dimensions",
    expansionDimension : "Expanded Dimensions",
    expansionMeasure : "Expanded Measures",
    modelSql : "SQL of Model"
  },
  viewModel: {
    dialogTitleView: "Model Information",
    schemaName: "Schema Name",
    tableName: "Table Name",
    modelName: "Model Name",
    modelDescription: "Model Description"
  },
  userManagement: {
    newUser: "New User",
    name: "Name",
    surname: "Surname",
    email: "E-mail",
    createdDate: "CREATED DATE",
    projectMembership: "Project Membership",
    groupMembership: "Group Membership",
    status: "Status",
    groupDialogTitle: "Group Membership of {0}",
    projectDialogTitle: "Project Membership of {0}",
    groupName: "Group Name",
    groupMemberCount: "Group Member Count",
    newRecord: "New Record",
    totalRecords: "Total {0} records",
    filter: "Filter",
    active: "Active Users",
    passive: "Passive Users",
    new: "New Users",
    all: "All"
  },
  userUpdate: {
    tabPersonal: "User Information",
    tabGroups: "Group Membership",
    tabRights: "User Rights",
    name: "Name",
    surname: "Surname",
    userName: "User Name",
    email: "E-mail",
    userType: "UserType",
    status: "Status",
    sendEmailForActivation: "Send Activation Link",
    allGroups: "All Groups",
    userGroups: "User Groups",
    addAll: "Add All",
    addToGroup: "Add to Group",
    deleteAll: "Delete All",
    deleteFromGroup:"Deletye From Group",
    find: "Find",
    userRole: "User Role :",
    groupRole: "Group Rolü :",
    groupName: "Group Name :",
    permissionSource: "Permission Source :",
    userRightsTitle: "User Permission Details",
    selectProjectOrCategory: " Select Project/Category",
    selectedProject: "Selected Project :",
    selectedCategory: "Selected Category :",
    deleteRight: "Delete Right",
    newRight: "New Right",
    EDITOR: "Editor",
    VISUAL_EDITOR: "Visual Editor",
    VISITOR: "Visitor",
    PROJECT_ADMIN: "Project Admin",
    Project: "Project",
    Category: "Category",
    CATEGORY: "CATEGORY",
    PROJECT: "PROJECT"
  },
  userCreator: {
    name: "Name",
    surname: "Surname",
    username: "User Name",
    email: "E-mail",
    password: "Password",
    dialogTitleUserCreate: "Create New User",
    userType: "User Type",
    DB: "DB User",
    LDAP: "LDAP User",
    passwordRules: {
      passwordCharacterSize: "At least 8 characters",
      passwordCharacterControl: "The password must be a minimum of 8 characters and contain upper and lower case letters and numbers.",
      mismatch: "New password mismatch!",
    }, 
    emailCheck: "Email is not valid!",
    dataValidation: "Please check the data you entered",
    emailIsNotDomainList: "The email you entered is not in the domain list!" 
  },
  passwordPage: {
    title: "Change Password",
    subtitle: "Enter the password sent to you in the old password field and your new password in the new password fields.",
    oldPassword: "Old Password",
    newPassword1: "New Password",
    newPassword2: "Confirm New Password",
    changePassword: "Change Password",
    passwordInvalid: "Password is wrong. Please try again."
  },
  activationMailPage: { 
    title: "Send Activation Mail",
    message: "Your activation link has expired. Click the button below to receive a reactivation email.",
    sendMail: "Send Activation Mail",
    title2: "LDAP User Activation",
    userActivatedSuccesfully: "The user has been successfully activated!"
  },
  groupManagement: {
    groupManagement: "Group Management",
    newGroup: "New Group",
    groupName: "Group Name",
    userCount: "User Count",
    createdDate: "Created Date",
    status: "Status",  
    newRecord: "New Record",
    totalRecords: "Total {0} records",
    filter: "Filter",
    active: "Aktive Groups",
    passive: "Passive Group",
    all: "All",
    userDialogTitle: "Group {0} Users",
    name: "Name",
    surname: "Surname",
    email: "E-mail",
    groupAttendanceDate: "Group Attendance Date"
  },
  groupCreator: {
    dialogTitle: "Create New Group",
    groupName: "Group Name",
  },
  groupUpdate: {
    tabGroupData: "GROUP DATA",
    tabGroupUsers: "GROUP MEMBERS",
    tabGroupRights: "GROUP PERMISSIONS",
    groupName: "Group Name",
    allUsers: "All Users",
    groupUsers: "Group Users",
    addAll: "Add All",
    addToGroup: "Add to Group",
    deleteAll: "Delete All",
    deleteFromGroup:"Delete From Group",
    find: "Find",
    groupRightsTitle: "Group Permission Details",
    selectedProject: "Selected Project :",
    selectedCategory: "Selected Category :",
    selectProjectOrCategory: "Select Project/Category",
    deleteRight: "Delete Permission",
    newRight: "New Permission",
    role: "Role :",
    permissionSource: "Permission Source :",
    rightCreator: {
      dialogTitleCreate: "New Permission",
      project: "Select Project",
      levelMessage: "Select the level you want to give permission.",
      roleMessage: "Specify a role to be used at the selected permission source and its sublevels.",
      role: "Select role",
      EDITOR: "Editor",
      VISUAL_EDITOR: "Visual Editor",
      VISITOR: "Visitor",
      levelMessageWarning: "Please select the level you want to give permission!",
    }
  },
  projectCategoryAuth: {
    projectSelection: "Select Project",
    newRight: "New Right",
    type: "Type",
    userGroupName: "User/Group Name",
    name: "Name",
    surname: "Surname",
    role: "Role",
    permissionSource: "Permission Source",
    rightCreationDate: "Right Creation Date",
    operations: "Operations",
    newRightButton: "New Right",
    deleteRight: "Delete Right",
    projectManager:"Project Manager",
    editor: "Editor",
    viewer: "Visitor",
    userGroupPermissionText: "User/Group Rights",
    categoryPermissionText: "Selected Category Rights",
    projectPermissionText: "Rights Transferred from Project or Upper Categories",
  },
  userGroupRightCreator: {
    name: "Name",
    surname: "Surname",
    username: "User Name",
    groupName: "Group Name",
    userCount: "User Count",
    selectedProject: "Selected Project/Category",
    tabUserList: "USER LIST",
    tabGroupList: "GROUP LIST",
  },
  projectMembers: {
    title: "{0} Project Members",
    type: "Type",
    userGroupName: "User/Group Name",
    name: "Name",
    surname: "Surname",
    status: "Status",
    active: "Active",
    passive: "Passive",
    initialized: "New Record",
    userPermissionTitle: "Flow of Authority of User {0}",
    groupPermissionTitle: "low of Authority of Group {0}",
    USER: "User",
    GROUP: "Group",
  },
  projectAuth: {
    title: "{0} Project Authorization",
    newRight: "New Right",
  },
  errorPages: {
    error404Msg: "Page not found.",
    error500Msg: "A system error has occurred. Please contact your system administrator.",
    homePage: "Home Page"
  },
  searchPage: {
    pageTitle: "Search Results",
    filterType: "Type",
    filterCreated: "Creator",
    filterCreateDate: "Create Date",
    filterUpdateDate: "Update Date",
    project: "Project",
    category: "Category",
    panel: "Panel",
    report: "Report",
    model: "Model",
    totalRecords: "Total {0} records",
    type: "Type",
    title: "Title",
    creator: "Creator",
    createdDate: "Create Date",
    updatedDate: "Update Date",
    path: "Path",
  }, 
  subscription: {
    titleCreate: "Create Subscription",
    titleUpdate: "Update Subscription",
    name: "Subscription Name",
    subscriptionNameCharacterSize: "Subscription name must be maximum 50 characters!",
    task: "Task",
    subscriptionTaskCharacterSize: "Subscription task must be maximum 100 characters!",
    message: "Message",
    period: "Frequency",
    users: "Users",
    groups: "Groups"
  },
  subscriptionPage: {
    title: "Subscription Management",
    name: "Subscription Name",
    projectName: "Project Name",
    panelName: "Panel Name",
    period: "Period",
    creator: "Creator",
    createdDate: "Create Date",
    lastSendDate: "Last Send Date",
    status: "Status",
    operations: "Operations",
    view: "Detail",
    update: "Update",
    delete: "Delete", 
    active: "Active",
    passive: "Passive",
    totalRecords: "Total {0} records",
  },
  subscriptionView: {
    subsViewTitle: "Subscription Detail",
    subsListTitle: "Panel Subscription",
    name: "Subscription Name",
    panelName: "Panel Name",
    task: "Task",
    message: "Mesage",
    period: "Period",
    creator: "Subscription Creator",
    createdDate: "Create Date",
    status: "Status",
    users: "Users",
    groups: "Groups"
  },
  scheduleManagement: {
    title: "Schedule Management",
    newSchedule: "New Schedule",
    scheduleName: "Schedule Name",
    period: "Period",
    startDate: "Start Date",
    endDate: "End Date",
    subscriptions: "Subscriptions",
    operations: "Operations",
    update: "Update",
    delete: "Delete",
    totalRecords: "Total {0} records",
    subsDialogTitle: "Subscriptions of Schedule {0}",
    subsName: "Subscription Name",
    project: "Project",
    creator: "Creator",
    scheduleNameCharacterSize: "Schedule name must be maximum 50 characters!",
    scheduleCreator:{
      titleCreate: "New Schedule",
      titleUpdate: "Update Schedule",
      scheduleName: "Schedule Name",
      period: "Period",
      DAILY: "Daily",
      WEEKLY: "Weekly",
      MONTHLY: "Monthly",
      hour: "Hour",
      startDate: "Start Date",
      endDate: "End Date",
      daySelection: "Day Selection",
      MONDAY: "Monday",
      TUESDAY: "Tuesday",
      WEDNESDAY: "Wednesday",
      THURSDAY: "Thursday",
      FRIDAY: "Friday",
      SATURDAY: "Saturday",
      SUNDAY: "Sunday"
    }
  },
  openId: {
    title: "OpenID Configuration",
    url: "Url",
    clientId: "Client ID",
    realm: "Realm",
    redirectUrl: "Redirect Url",
    domainList: "OpenID Domain List",
    domainNameCheck: "Domain name is not valid!",
    delete: "Delete Domain",
    add: "Add Domain",
    domainUsedInUser: "The domain you selected cannot be deleted because it is used!"
  },
  auditLog: {
    mainActivityTitle: "Main Activity",
    subActivityTitle: "Activity",
    order: "Order Criterions",
    orderDirection: "Order Direction",
    ASC: "Ascending Order",
    DESC: "Descending Order",
    user: "User",
    project: "Project",
    search: "Search",
    clear: "Clear",
    filter: "Search Criterions",
    SUBSCRIPTION: "Subscription",
    IMAGE: "Widget",
    GROUP_OPERATIONS: "Group Operations",
    CATEGORY: "Category",
    AUTHENTICATION: "Authentication",
    USER_OPERATIONS: "User Operations",
    PANEL: "Panel",
    PROFILE: "Profile",
    PROJECTS: "Projects",
    DATA_MODEL: "Data Model",
    TIMELINE: "Timeline",

    SUBSCRIPTION_CREATED: "Subscription Created",
    SUBSCRIPTION_UPDATE: "Subscription Updated",
    SUBSCRIPTION_DELETED: "Subscription Deleted",
    SUBSCRIPTION_ACTIVATED: "Subscription Activated",
    SUBSCRIPTION_DEACTIVATED: "Subscription Deactivated",
    SUBSCRIPTION_SEND_EMAIL: "Subscription Mail Send",

    IMAGE_CREATE: "Create Widget",
    IMAGE_UPDATE: "Update Widget",
    IMAGE_DELETE: "Delete Widget",
    IMAGE_SQLQUERY_CHANGE: "Widget SQL Update",
    IMAGE_SUMMARY_DATA_DOWNLOAD: "Widget Summary Data Download",
    IMAGE_PURE_DATA_DOWNLOAD: "Widget Pure Data Download",

    GROUP_CREATED: "Group Created",
    GROUP_UPDATED: "Group Updated",
    GROUP_ACTIVATED: "Group Activated",
    GROUP_DEACTIVATED: "Group Deactivated",
    ADD_PRIVILEGE_TO_GROUP: "Add Privilege to Group",
    REMOVE_PRIVILEGE_TO_GROUP: "Delete Privilege from Group",

    CATEGORY_CREATE: "Category Created",
    CATEGORY_UPDATE: "Category Updated",
    CATEGORY_DELETE: "Category Deleted",

    LOGIN: "Login",
    LOGOUT: "Logout",
    LOGIN_BAD_CREDENTIAL: "UserName/Password Wrong", 
    LOGIN_USER_INITIAL: "New User Created", 

    USER_CREATED: "User Created",
    USER_DELETED: "User Deleted",
    USER_UPDATE: "User Updated",
    SIMPLE_USER_CREATED: "Create LDAP User", 
    USER_ACTIVATED: "User Activated",
    USER_DEACTIVATED: "User Deactivated",
    USER_ADD_TO_GROUP: "User Added to Group",
    USER_REMOVE_FROM_GROUP: "User Removed From Group",
    USER_PRIVILEGE_ADD: "User Privilege Added",
    USER_PRIVILEGE_REMOVE: "User Privilege Removed",
    
    PANEL_CREATE: "Panel Created",
    PANEL_DELETE: "Panel Deleted",
    PANEL_UPDATE: "Panel Updated",
    PANEL_FILTER_CREATE: "Panel Default Filter Created",
    PANEL_FILTER_UPDATE: "Panel Default Filter Updated",
    PANEL_FILTER_DELETE: "Panel Default Filter Deleted",
    PANEL_SUBSCRIPTION: "Panel Subscription",

    PROFILE_UPDATE: "Profile Updated",
    PROFILE_PASS_CHANGE: "Profile Password Changed",

    PROJECT_CREATE: "Project Created",
    PROJECT_DELETE: "Project Deleted",
    PROJECT_UPDATE: "Project Updated",
    PROJECT_VISIT: "Project Visited",

    CATALOG_CREATE: "Catalog Created",
    CATALOG_RENAME: "Catalog Renamed",
    CATALOG_DELETED: "Catalog Deleted",
    DATAMODEL_CREATED: "Data Model Created",
    DATAMODEL_UPDATE: "Data Model Updated",
    DATAMODEL_DELETED: "Data Model Deleted ",

    TIMELINE_CREATE: "Timeline Created",
    TIMELINE_UPDATE: "Timeline Updated",
    TIMELINE_DELETE: "Timeline Deleted",
    
    TARIH_SAAT: "Date and Time",
    ACTIVITE_ANABASLIK: "Main Activity",
    ACTIVITE: "Activity",
    KULLANICI: "User",
    PROJE: "Project",
    ETKILENEN_OGE: "Effected Item",
    IP: "IP",
    VERSION: "Version",
    
    date:{
      startHour: "Start Hour",
      endHour: "End Hour",
      startDate: "Start Date",
      endDate: "End Date",
    },
    table: {
      dateTime: "Date and Time",
      mainActivity: "Main Activity",
      subActivity: "Activity",
      userName: "User",
      ip: "IP",
      version: "Version"
    },
    totalRecords: "Total {0} records",
  },
  dataSetting: {
    title: "Data Download Settings",
    delimiter: "CSV Delimiter Type",
    otherDelimiter: "Other",
    recordSize: "Downloadable Record Size",
    recordSizeWarningExcel: "Enter minimum 1 and maximum 1.048.576!",
    recordSizeWarningCsv: "Enter minimum 1 and maximum 10.000.000!",
    delimiterLengthWarning: "Delimiter length must be lower than 5!",
    subTitle1: "Download Settings in Excel File Format",
    subTitle2: "Download Settings in CSV File Format",
  },
  rawData: {
    title: "Download Raw Data",
    donwloadInfo: "Download Information",
    downloadFields: "Download Fields",
    xlsxRecordSize: "Excel Record Size",
    csvRecordSize: "CSV Record Size",
    fileFormat: "File Format",
    delimiter: "Delimiter Type",
    otherDelimiter: "Other",
    delimiterLengthWarning: "Delimiter size must be maximum 4!",
    retrieveData: "Retrieve Data",
    selectAll: "Select All",
    dataTooLargeError: "Since the download time of the data will take a long time, the file will be sent to your e-mail address."
  },
  // Vuetify components translations
  $vuetify: {
    noDataText: "No record found!",
    datePicker: {
      itemsSelected: ""
    },
    dataTable: {
      itemsPerPageText: "Number of records per page"
    },
    dataFooter: {
      pageText: "",
      itemsPerPageAll: "All"
    },
    dataIterator: {
      noResultsText: "No result found!"
    }
  }
};