<template>
  <div
    v-shortkey="['ctrl', '/']"
    class="d-flex flex-grow-1"
    @shortkey="onKeyup"
  >
    <!-- Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      class="leftPanelStyle"
      :right="$vuetify.rtl"
      :light="menuTheme === 'light'"
      :dark="menuTheme === 'dark'"
      data-test-id="def-lay-nav"
    >
      <!-- Navigation menu info -->
      <template v-slot:prepend>
        <div class="pa-2">
          <div
            @click="navigateToProjects"
            class="title font-weight-bold text-uppercase white--text"
            style="cursor: pointer"
            data-test-id="def-lay-projects"
          >
            {{ product.name }}
            <img
              src="../assets/images/foresight-lines.png"
              style="width: 18px"
            />
          </div>

          <div class="overline grey--text" data-test-id="def-lay-title">
            {{ $t("menu.title") }}
          </div>
        </div>
      </template>

      <div
        @click="navigateToProjects"
        style="padding: 15px; fontweight: bold; fontsize: 14px; cursor: pointer"
        data-test-id="def-lay-home"
      >
        <v-icon data-test-id="def-lay-home-icon" class="pr-1">mdi-home</v-icon>
        <span style="margin-top: 5px" data-test-id="def-lay-home-title">{{
          $t("menu.home")
        }}</span>
      </div>

      <div
        v-if="selectedProject.id"
        class="pa-1 font-weight-bold blue--text"
        data-test-id="def-lay-apps"
      >
        <apps-menu></apps-menu>
        <span
          @click="navigateTo(selectedProject)"
          style="cursor: pointer"
          data-test-id="def-lay-sel-project"
        >
          {{
            selectedProject.id ? selectedProject.title : $t("project.projects")
          }}</span
        >
      </div>

      <!-- Navigation menu -->
      <main-menu :menu="navigation.menu" />
      <!-- Navigation menu footer -->
      <template v-slot:append>
        <!-- Footer navigation links -->
        <div class="pa-1 text-center">
          <v-btn
            v-for="(item, index) in navigation.footer"
            :key="index"
            :href="item.href"
            :target="item.target"
            small
            text
            :data-test-id="'def-lay-nav-btn-' + index"
          >
            {{ item.key ? $t(item.key) : item.text }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar
      app
      class="appBarClass"
      :color="isToolbarDetached ? 'surface' : undefined"
      :flat="isToolbarDetached"
      :light="toolbarTheme === 'light'"
      :dark="toolbarTheme === 'dark'"
      data-test-id="def-lay-app-bar"
    >
      <v-card
        class="flex-grow-1 d-flex"
        :class="[isToolbarDetached ? 'pa-1 mt-3 mx-1' : 'pa-0 ma-0']"
        :flat="!isToolbarDetached"
        data-test-id="def-lay-app-bar-card"
      >
        <div class="d-flex flex-grow-1 align-center">
          <!-- search input mobile -->
          <v-text-field
            v-if="showSearch"
            append-icon="mdi-close"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
            hide-details
            solo
            flat
            autofocus
            @click:append="showSearch = false"
            data-test-id="def-lay-search-field-mobile"
          ></v-text-field>

          <div v-else class="d-flex flex-grow-1 align-center">
            <v-app-bar-nav-icon
              data-test-id="def-lay-app-bar-icon"
              @click.stop="clickedNavBar"
            ></v-app-bar-nav-icon>

            <v-spacer class="d-none d-lg-block"></v-spacer>
            <!--  adding no-filter property to v-autocomplete component. If your items is changing dynamically (e.g. items is changed after requests to server), additional filtering may break headers and dividers.-->
            <v-autocomplete
              data-test-id="def-lay-search-field"
              class="searchField"
              v-model="selectedItem"
              :items="items"
              no-filter
              :search-input.sync="search"
              color="white"
              hide-selected
              item-text="name"
              item-value="name"
              :placeholder="$t('menu.search')"
              prepend-inner-icon="mdi-magnify"
              return-object
              :clearable="true"
              @change="navigateToPage"
            >
              <template #no-data>
                <p class="pt-2" style="color: gray">
                  {{ $t("menu.noResultsFound") }}
                </p>
              </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-avatar v-if="data.item.icon">
                    <v-icon>mdi-magnify</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.name"
                    ></v-list-item-title>
                    <v-divider></v-divider>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>

            <v-spacer class="d-none d-lg-block"></v-spacer>

            <v-btn
              class="d-block d-sm-none"
              icon
              @click="showSearch = true"
              data-test-id="def-lay-show-search"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <toolbar-language @locale-changed="localeChanged" />
            <div class="mr-1">
              <toolbar-notifications />
            </div>
            <toolbar-user />
          </div>
        </div>
      </v-card>
    </v-app-bar>

    <v-main class="pt-3 pr-1">
      <v-container
        class="fill-height"
        :fluid="!isContentBoxed"
        data-test-id="def-lay-container"
      >
        <v-layout>
          <slot></slot>
        </v-layout>
      </v-container>

      <v-footer app inset data-test-id="def-lay-footer">
        <v-spacer></v-spacer>
        <div class="overline">Foresight © {{ new Date().getFullYear() }}</div>
      </v-footer>
    </v-main>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

// navigation menu configurations
import config from "../configs";
import utilities from "../utilities/utilities";
import MainMenu from "../components/navigation/MainMenu";
import AppsMenu from "../components/navigation/AppsMenu";
import ToolbarUser from "../components/toolbar/ToolbarUser";
import ToolbarLanguage from "../components/toolbar/ToolbarLanguage";
import ToolbarNotifications from "../components/toolbar/ToolbarNotifications";
import permissions from "./../configs/permissions";
export default {
  components: {
    MainMenu,
    AppsMenu,
    ToolbarUser,
    ToolbarLanguage,
    ToolbarNotifications,
  },
  data() {
    return {
      search: null,
      searchText: "",
      selectedItem: undefined,
      selectedProjectId: "",
      userPermissions: [],
      isSuperAdmin: false,
      drawer: null,
      showSearch: false,
      itemList: [],
      navigation: config.navigation,
    };
  },
  computed: {
    ...mapState("app", [
      "product",
      "isContentBoxed",
      "menuTheme",
      "toolbarTheme",
      "isToolbarDetached",
      "userPermission",
      "selectedProject",
    ]),

    items() {
      return this.itemList;
    },
  },
  created() {
    this.selectedProjectId = this.selectedProject.id;
  },

  watch: {
    search(val) {
      this.searchText = val;
      if (!val || val.length < 3) {
        this.itemList = [];
        return;
      }
      const list1 = [
        { name: "Tüm Sonuçları Gör", group: "all", icon: "true" },
        { header: "Projeler" },
        {
          name: "Demo Foresight",
          group: "project",
          project: {
            id: "6a9a5df8-ddf2-45b6-a312-83571a7af300",
            title: "Demo Foresight",
            permissions: [
              "CreateProject",
              "VisitProject",
              "EditProject",
              "DeleteProject",
              "CreateCategory",
              "EditCategory",
              "DeleteCategory",
              "VisitCategory",
            ],
          },
        },
        { header: "Kategoriler" },
        {
          name: "Belgenet",
          group: "category",
          category: {
            id: 51,
            title: "Belgenet",
            project: {
              id: "6a9a5df8-ddf2-45b6-a312-83571a7af300",
              title: "Demo Foresight",
              permissions: [
                "CreateProject",
                "VisitProject",
                "EditProject",
                "DeleteProject",
                "CreateCategory",
                "EditCategory",
                "DeleteCategory",
                "VisitCategory",
              ],
            },
          },
        },
        { header: "Paneller", count: "5" },
        {
          name: "2014 Yerel Seçimler",
          group: "panel",
          panel: {
            id: 174,
            title: "2014 Yerel Seçimler",
            project: {
              id: "6a9a5df8-ddf2-45b6-a312-83571a7af300",
              title: "Demo Foresight",
              permissions: [
                "CreateProject",
                "VisitProject",
                "EditProject",
                "DeleteProject",
                "CreateCategory",
                "EditCategory",
                "DeleteCategory",
                "VisitCategory",
              ],
            },
          },
        },
        {
          name: "İBB Açık Veri",
          group: "panel",
          panel: {
            id: 144,
            title: "İBB Açık Veri",
            project: {
              id: "6a9a5df8-ddf2-45b6-a312-83571a7af300",
              title: "Demo Foresight",
              permissions: [
                "CreateProject",
                "VisitProject",
                "EditProject",
                "DeleteProject",
                "CreateCategory",
                "EditCategory",
                "DeleteCategory",
                "VisitCategory",
              ],
            },
          },
        },
        {
          name: "Belgenet-Rapor",
          group: "panel",
          panel: {
            id: 162,
            title: "Belgenet-Rapor",
            project: {
              id: "6a9a5df8-ddf2-45b6-a312-83571a7af300",
              title: "Demo Foresight",
              permissions: [
                "CreateProject",
                "VisitProject",
                "EditProject",
                "DeleteProject",
                "CreateCategory",
                "EditCategory",
                "DeleteCategory",
                "VisitCategory",
              ],
            },
          },
        },
        { header: "Raporlar" },
        { name: "UAB- Gelen Yolcu Pivot Tablosu", group: "report" },
        { header: "Veri Modeli" },
        {
          name: "halk_oulamasi_2017",
          group: "model",
          model: {
            id: 260,
            catalogId: 40,
            title: "halk_oulamasi_2017",
            project: {
              id: "6a9a5df8-ddf2-45b6-a312-83571a7af300",
              title: "Demo Foresight",
              permissions: [
                "CreateProject",
                "VisitProject",
                "EditProject",
                "DeleteProject",
                "CreateCategory",
                "EditCategory",
                "DeleteCategory",
                "VisitCategory",
              ],
            },
          },
        },
        {
          name: "halk_oulamasi_2017_yeni",
          group: "model",
          model: {
            id: 277,
            catalogId: 40,
            title: "halk_oulamasi_2017_yeni",
            project: {
              id: "6a9a5df8-ddf2-45b6-a312-83571a7af300",
              title: "Demo Foresight",
              permissions: [
                "CreateProject",
                "VisitProject",
                "EditProject",
                "DeleteProject",
                "CreateCategory",
                "EditCategory",
                "DeleteCategory",
                "VisitCategory",
              ],
            },
          },
        },
      ];

      list1.forEach((item) => {
        if (item.header && item.count) {
          item.header = item.header + " (+" + item.count + " sonuç daha)";
        }
      });
      this.itemList = utilities.clone(list1);
    },
    userPermission: {
      handler(newVal, oldVal) {
        this.userPermissions = newVal.permissions;
        this.isSuperAdmin = newVal.isSuperAdmin;
      },
    },
    selectedProject: {
      immediate: false,
      handler(newVal, oldVal) {
        if (newVal.id !== oldVal.id) this.selectedProjectId = newVal.id;
      },
    },
    isSuperAdmin: {
      immediate: false,
      handler(newVal, oldVal) {
       /* if (newVal) {
          this.navigation.menu = [];
        }*/
        if (newVal && !this.selectedProjectId) {
          this.navigation.menu = [];//proje seçiliyken herhangi bir sayfaya girdiğinde(ex.kataloglar sayfası) isSuperadmin çağrılıyor ve menu sıfırlanıyor. Bu nedenle if kontrolünün içine aldım.
          let menuIndex = -1; //menüye eklenen itemlar değişkenlik gösterdiği için eklendi.yönetim olmadan veri menüsü gelebilir.
          if (
            this.userPermissions.includes(permissions.user.userManagement) ||
            this.userPermissions.includes(permissions.log.viewAuditLogs) ||
            this.userPermissions.includes(permissions.group.groupManagement)
          ) {
            this.navigation.menu.push({
              text: this.$t("menu.management"),
              key: "mng",
              items: [],
            });
            menuIndex++;
          }
          if (this.userPermissions.includes(permissions.user.userManagement)) {
            if (this.navigation.menu[menuIndex].key === "mng") {
              this.navigation.menu[menuIndex].items.push({
                icon: "mdi-account",
                text: this.$t("menu.userManagement"),
                link: "/users",
              });
            }
          }
          if (this.userPermissions.includes(permissions.group.groupManagement)) {
            if (this.navigation.menu[menuIndex].key === "mng") {
              this.navigation.menu[menuIndex].items.push({
                icon: "mdi-account-multiple",
                text: this.$t("menu.groupManagement"),
                link: "/groups",
              });
            }
          }

          if (this.userPermissions.includes(permissions.log.viewAuditLogs)) {
            if (this.navigation.menu[menuIndex].key === "mng") {
              this.navigation.menu[menuIndex].items.push({
                icon: "mdi-timeline-clock",
                text: this.$t("menu.auditLogPage"),
                link: "/audit",
              });
            }
          }
          if (this.userPermissions.includes(permissions.subscription.viewSubsPageAdmin)) {
            if (this.navigation.menu[menuIndex].key === "mng") {
              this.navigation.menu[menuIndex].items.push({
                icon: "mdi-email-send",
                text: this.$t("menu.subscriptionManagement"),
                link: "/subs", //super adminin göreceği ekran
              });
            }
      }
          

          if (
            this.userPermissions.includes(permissions.schedule.viewTimeline)
          ) {
            if (this.navigation.menu[menuIndex].key === "mng") {
              this.navigation.menu[menuIndex].items.push({
                icon: "mdi-timetable",
                text: this.$t("menu.scheduleManagement"),
                link: "/schedule",
              });
            }
          }

          //SYSTEM
          if (
            this.userPermissions.includes(permissions.system.openIdManagement) 
          ) {
            this.navigation.menu.push({
              text: this.$t("menu.system"),
              key: "sys",
              items: [],
            });
            menuIndex++;
          }

          if (
            this.userPermissions.includes(permissions.system.openIdManagement)
          ) {
            if (this.navigation.menu[menuIndex].key === "sys") {
              this.navigation.menu[menuIndex].items.push({
                icon: "mdi-hammer-wrench",
                text: this.$t("menu.openIdManagement"),
                link: "/openid",
              });
            }
          }


          
        }
      },
    },
    selectedProjectId: {
      immediate: true,
      handler(newVal, oldVal) {
        if (!newVal) {
          this.noProjectSelected();
        } else {
          if (newVal !== oldVal) this.projectSelected();
        }
      },
    },
  },

  methods: {
    ...mapActions("app", [
      "selectProject",
      "unselectProject",
      "checkIfUserIsAdmin",
    ]),
    localeChanged(locale) {
      if (this.selectedProjectId) this.projectSelected();
      else this.noProjectSelected();
      this.$forceUpdate();
    },
    noProjectSelected() {
      this.navigation.menu = [];
      let menuIndex = -1; //menüye eklenen itemlar değişkenlik gösterdiği için eklendi.yönetim olmadan veri menüsü gelebilir.
      const copyPermissions =
        this.userPermission.permissions.length > 0
          ? this.userPermission.permissions
          : this.userPermissions;

      if (
        copyPermissions.includes(permissions.user.userManagement) ||
        copyPermissions.includes(permissions.log.viewAuditLogs) ||
        copyPermissions.includes(permissions.group.groupManagement)
      ) {
        this.navigation.menu.push({
          text: this.$t("menu.management"),
          key: "mng",
          items: [],
        });
        menuIndex++;
      }
      if (copyPermissions.includes(permissions.user.userManagement)) {
        if (this.navigation.menu[menuIndex].key === "mng") {
          this.navigation.menu[menuIndex].items.push({
            icon: "mdi-account",
            text: this.$t("menu.userManagement"),
            link: "/users",
          });
        }
      }
      if (copyPermissions.includes(permissions.group.groupManagement)) {
        if (this.navigation.menu[menuIndex].key === "mng") {
          this.navigation.menu[menuIndex].items.push({
            icon: "mdi-account-multiple",
            text: this.$t("menu.groupManagement"),
            link: "/groups",
          });
        }
      }

      if (copyPermissions.includes(permissions.log.viewAuditLogs)) {
        if (this.navigation.menu[menuIndex].key === "mng") {
          this.navigation.menu[menuIndex].items.push({
            icon: "mdi-timeline-clock",
            text: this.$t("menu.auditLogPage"),
            link: "/audit",
          });
        }
      }

      if (copyPermissions.includes(permissions.subscription.viewSubsPageAdmin)) {
            if (this.navigation.menu[menuIndex].key === "mng") {
              this.navigation.menu[menuIndex].items.push({
                icon: "mdi-email-send",
                text: this.$t("menu.subscriptionManagement"),
                link: "/subs",
              });
            }
      }

      if (copyPermissions.includes(permissions.schedule.viewTimeline)) {
        if (this.navigation.menu[menuIndex].key === "mng") {
          this.navigation.menu[menuIndex].items.push({
            icon: "mdi-timetable",
            text: this.$t("menu.scheduleManagement"),
            link: "/schedule",
          });
        }
      }

      //SYSTEM
      if (
        copyPermissions.includes(permissions.system.openIdManagement) 
          ) {
            this.navigation.menu.push({
              text: this.$t("menu.system"),
              key: "sys",
              items: [],
            });
            menuIndex++;
          }

          if (
            copyPermissions.includes(permissions.system.openIdManagement)
          ) {
            if (this.navigation.menu[menuIndex].key === "sys") {
              this.navigation.menu[menuIndex].items.push({
                icon: "mdi-hammer-wrench",
                text: this.$t("menu.openIdManagement"),
                link: "/openid",
              });
            }
          }
    },
    projectSelected() {
      //Proje seçildiyse
      this.navigation.menu = [];
      let menuIndex = -1; //menüye eklenen itemlar değişkenlik gösterdiği için eklendi.yönetim olmadan veri menüsü gelebilir.
      if (
        this.selectedProject.permissions.includes(
          permissions.user.viewProjectMembers
        ) ||
        this.selectedProject.permissions.includes(
          permissions.subscription.viewSubsPagePm
        )
      ) {
        this.navigation.menu.push({
          text: this.$t("menu.management"),
          key: "mng",
          items: [],
        });
        menuIndex++;
      }
      if (
        this.selectedProject.permissions.includes(
          permissions.user.viewProjectMembers
        )) {
        if (this.navigation.menu[menuIndex].key === "mng") {
          this.navigation.menu[menuIndex].items.push({
            icon: "mdi-account-group",
            text: this.$t("menu.projectMembers"),
            link: "/projectmembers",
          });
          
        }
      }
      if (
        this.selectedProject.permissions.includes(
          permissions.subscription.viewSubsPagePm
        )) {
        if (this.navigation.menu[menuIndex].key === "mng") {
          this.navigation.menu[menuIndex].items.push({
            icon: "mdi-email-send",
            text: this.$t("menu.subscriptionManagement"),
            link: "/subspm",
          });
          
        }
      }
      //VERİ
      if (
        this.selectedProject.permissions.includes(
          permissions.catalog.visit
        ) || this.selectedProject.permissions.includes(
          permissions.catalog.settings
        )
      ) {
        this.navigation.menu.push({
          text: this.$t("menu.data"),
          key: "data",
          items: [],
        });
        menuIndex++;
      }

      if (
        this.selectedProject.permissions.includes(permissions.catalog.visit)
      ) {
        if (this.navigation.menu[menuIndex].key === "data") {
          this.navigation.menu[menuIndex].items.push({
            icon: "mdi-package-variant-closed",
              text: this.$t("menu.catalogs"),
              link: "/catalog",
          });
        }
      }

      //veri indirme ayarları
      if (
        this.selectedProject.permissions.includes(permissions.catalog.settings)
      ) {
        if (this.navigation.menu[menuIndex].key === "data") {
          this.navigation.menu[menuIndex].items.push({
            icon: "mdi-hammer-wrench",
              text: this.$t("menu.dataSettings"),
              link: "/dataSettings",
          });
        }
      }

    },
    navigateToPage() {
      if (this.selectedItem) {
        //search field clear yapınca null geldiği için bu kontrol eklendi.
        if (this.selectedItem.group === "project")
          this.navigateToProjectPage(this.selectedItem.project);
        else if (this.selectedItem.group === "category")
          this.navigateToCategoryPage(this.selectedItem.category);
        else if (this.selectedItem.group === "panel")
          this.navigateToPanelPage(this.selectedItem.panel);
        else if (this.selectedItem.group === "model")
          this.navigateToModelDetailPage(this.selectedItem.model);
        else if (this.selectedItem.group === "all")
          this.navigateToSearchPage(this.searchText);
      }
    },
    navigateToProjectPage(project) {
      this.$storage.set("selectedProjectId", project.id);
      this.selectProject(project);
      this.$router
        .push({ name: "panels", params: { pid: project.id } })
        .catch((err) => {
          //console 'a hatayı basmaması için eklendi.
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            console.log(err);
          }
        });
    },
    navigateToCategoryPage(category) {
      this.$router
        .push({ name: "categories", params: { id: category.id } })
        .catch((err) => {
          //console 'a hatayı basmaması için eklendi.
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            console.log(err);
          }
        });
      this.$storage.set("selectedProjectId", category.project.id); //yönlendirilen kategorinin aiy olduğu proje set edilmeli
      this.selectProject(category.project);
    },
    navigateToPanelPage(panel) {
      this.$router
        .push({ name: "panel", params: { id: panel.id } })
        .catch((err) => {
          //console 'a hatayı basmaması için eklendi.
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            console.log(err);
          }
        });
      //yönlendirilen panelin ait olduğu proje set edilmeli
      this.$storage.set("selectedProjectId", panel.project.id);
      this.selectProject(panel.project);
    },
    navigateToModelDetailPage(model) {
      this.$router
        .push({
          name: "modeldetail",
          params: {
            id: model.id,
            cid: model.catalogId,
            title: model.title,
          },
        })
        .catch((err) => {
          //console 'a hatayı basmaması için eklendi.
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            console.log("hata oluştu:" + err);
            //route işleminde hata oluştuğu zaman tekrar aşağıdaki kodu çağırmak gerekiyor.
          }
        });
      //yönlendirilen panelin ait olduğu proje set edilmeli
      this.$storage.set("selectedProjectId", model.project.id);
      this.selectProject(model.project);
    },
    navigateToSearchPage(text) {
      this.$router
        .push({ name: "search", params: { text: text } })
        .catch((err) => {
          //console 'a hatayı basmaması için eklendi.
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            console.log(err);
          }
        });
    },

    onKeyup(e) {
      this.$refs.search.focus();
    },
    navigateToProjects() {
      this.unselectProject();
      this.checkIfUserIsAdmin({ vm: this });
      this.$router.push({ name: "projects" }).catch((err) => {
        //console 'a hatayı basmaması için eklendi.
        if (
          err.name !== "NavigationDuplicated" &&
          !err.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          console.log(err);
        }
      });
    },
    navigateTo(project) {
      if (project.id) {
        this.$storage.set("selectedProjectId", project.id);
        this.selectProject(project);
        this.$router
          .push({ name: "panels", params: { pid: project.id } })
          .catch((err) => {
            //console 'a hatayı basmaması için eklendi.
            if (
              err.name !== "NavigationDuplicated" &&
              !err.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              console.log(err);
            }
          });
      }
    },
    clickedNavBar() {
      this.drawer = !this.drawer;
      this.$eventBus.$emit("panel-resized"); //ekran küçültüp büyütünce widgetların resize edilmesi için event eklendi.
    },
  },
};
</script>

<style scoped>
.searchField {
  height: 20px;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
  z-index: 999999;
}
.appBarClass {
  z-index: 999;
}
</style>
