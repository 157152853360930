export default {
  common: {
    add: "Ekle",
    save: "Kaydet",
    cancel: "İptal",
    continue: "Devam Et",
    required: "* Zorunlu alan",
    close: "Kapat",
    loading: "Yükleniyor",
    reset: "Sıfırla",
    clear: "Temizle",
    next: "İleri",
    back: "Geri",
    finish: "Tamamla",
    download: "İndir"
  },
  dialog: {
    yes: "Evet",
    no: "Hayır",
    deleteCategoryWarning:
      "{0} isimli kategori içerisindeki içerikler ile beraber silinecektir. Devam etmek istiyor musunuz?",
    deletePanelWarning:
      "{0} isimli panel silinecektir. Devam etmek istiyor musunuz?",
    deleteReportWarning:
      "{0} isimli rapor silinecektir. Devam etmek istiyor musunuz?",
    moveWarning1: "{0} {1} kategorisine taşınacaktır. Devam etmek istiyor musunuz?",
    moveWarning2: "{0} ana sayfaya taşınacaktır. Devam etmek istiyor musunuz?",
    deleteProjectWarning:
      "{0} isimli proje silinecektir. Devam etmek istiyor musunuz?",
    saveProjectWarning:
      " Seçtiğiniz kullanıcı bu projede bazı yetkilere sahiptir. Devam etmek istiyor musunuz?",
    deleteCatalogWarning:
      "{0} isimli katalog silinecektir. Devam etmek istiyor musunuz?",
    savingDataWarning: "Seçtiğiniz kullanıcının bilgilerini kaydetmek istiyormusunuz?",
    deleteModelWarning:
      "{0} isimli model silinecektir. Devam etmek istiyor musunuz?", 
    savingGroupDataWarning: "Seçtiğiniz grubun bilgilerini kaydetmek istiyormusunuz?", 
    deleteRightWarning:
      "{0} rolü silinecektir. Devam etmek istiyor musunuz?",
    deleteUserGroupRightWarning:
      "{0} isimli kullanıcı/group yetkisi silinecektir. Devam etmek istiyor musunuz?",
    deleteSubscriptionWarning:
      "{0} isimli abonelik silinecektir. Devam etmek istiyor musunuz?",
    activateSubscriptionWarning:
      "{0} isimli aboneliğin bildirimleri açılacaktır. Devam etmek istiyor musunuz?",
    deactivateSubscriptionWarning:
      "{0} isimli aboneliğin bildirimleri kapatılacaktır. Devam etmek istiyor musunuz?",
    savingProjectDataWarning: "Proje bilgileri güncellenecektir. Devam etmek istiyor musunuz?",
    deleteScheduleWarning: "{0} isimli zaman çizelgesi silinecektir. Devam etmek istiyor musunuz?",
    deleteDomainWarning: "{0} isimli domain silinecektir. Devam etmek istiyor musunuz?",


  },
  error: {
    catalogExistsWarning: "Aynı isimde başka bir katalog bulunmaktadır!",
    completeFiltersWarning: "Lütfen filtreleri tamamlayınız!",
    measureAlreadyExists: 'Aynı isimde ölçütler bulunmaktadır!',
    dimensionAlreadyExists: 'Aynı isimde boyutlar bulunmaktadır!',
    dimensionOrMeasureAlreadyExists: 'Aynı isimde boyut ve ölçüt bulunmaktadır!',
    modelAlreadyExistsWarning: 'Aynı isimde başka bir model bulunmaktadır!',
    completeModelWarning: 'Lütfen modeli tamamlayınız!',
    passwordChangedEror: "Bir hata meydana geldi: ",
    invalidPassword: "Hatalı şifre girdiniz. Şifreniz en az 8 karakterden oluşmalı, en az bir sembol, bir küçük karakter ve bir büyük karakter içermelidir.",
    errorMessage: "Hata Oluştu!",
    errorSSOLogoutMessage: "SSO Çıkışı yapılamamıştır. Lütfen tekrar deneyiniz.",
    expansionInvalidMessage: "İlişkilendirme Kurulamamıştır! Eşleştirilmek istenilen kolonlarda verilerin karşılığı bulunamamıştır. Veritabanı düzeyinde gerekli düzeltmeleri yaptıktan sonra tekrar deneyiniz." ,
    invalidSqlStatement: "Lütfen geçerli SQL ifadesi giriniz!",
    categoryExistsWarning: "Aynı isimde başka bir kategori bulunmaktadır!",
    panelExistsWarning: "Aynı isimde başka bir panel bulunmaktadır!",
    groupExistsWarning: "Aynı isimde başka bir grup bulunmaktadır!",
    userGroupSelectWarning: "Kullanıcı seçmelisiniz!",
    deactivateProjectManager: " kullanıcısının proje yöneticisi olduğu projeler bulunmaktadır. Lütfen önce bu projelerin proje yöneticilerini değiştiriniz.",
    dublicateDimensionName: "Aynı isimde boyut/ölçüt mevcut. Lütfen gerekli düzeltmeyi yapınız.",
    badSqlStatement: "Sql ifadesi veya tablo ismi yanlış! Lütfen sql ifadesini kontrol ediniz.",
    projectDeleteWarning1: "Silmek istediğiniz proje altında yetki tanımlaması vardır. Lütfen önce yetkileri siliniz!",
    projectDeleteWarning2: "Silmek istediğiniz proje altında kategori/katalog vardır. Bu proje silinemez!",
    scheduleExistsWarning: "Aynı isimde başka bir zaman çizelgesi bulunmaktadır!",
    sendMailError: "Aktivasyon maili gönderirken hata oluştu.",
    alreadyDomainContent: "Aynı isimde kayıtlı domain bulunmaktadır!",
    userExistsError: "Aynı kullanıcı adı/mail adresine sahip kullanıcı bulunmaktadır!",
    timelineExistsError: "Aynı isme sahip çizelge bulunmaktadır!",
    domainAlreadyExistsWarning: "Aynı isimde domain kaydedilemez!",
    sendRawDataEmailError: "E-posta gönderilirken hata oluştu!",
    subscriptionAlreadyExistsWarning: "Aynı isimde abonelik mevcuttur!",
    groupExistsWarning: "Aynı isimde başka bir grup bulunmaktadır!",
  },
  login: {
    username: "Kullanıcı Adı",
    password: "Parola",
    usernameOrPasswordInvalid: "Kullanıcı adı ya da parola hatalı!",
    userUnauthorized: "Yetkiniz bulunmamaktadır!",
    button: "Giriş Yap",
    egovernmentbutton: "E-devlet Giriş",
    openId: "Türksat OpenId",
    forgot: "Parolamı Unuttum",
    updatePassword: "Parolayı Güncelle",
    invalidDatabaseConnection: "Veritabanı bağlantısı sağlanamadı. Giriş yapılan bilgileri kontrol ediniz.",
    sessionTimeOut: "Oturum Süresi Dolmuştur!",
    userNotActive: "Kullanıcı pasiftir. Lütfen sistem yöneticinize başvurunuz.",
    userNotFound: "Kullanıcı bulunamadı!",
    userActivated: "Kullanıcınız aktif durumdadır!",
    tokenFail: "Kullanıcı aktivasyonu geçersiz!",
    tokenExpired: "Kullanıcı aktivasyon süreniz sona ermiştir. Lütfen tekrar kullanıcı kaydı oluşturun!",
    initialPasswordWrong: "Eski şifreniz hatalı. Lütfen tekrar deneyiniz!",
    userInitialized: "Kullanıcı henüz aktif değil. E-postanıza gelen linke tıklayarak kullanıcınızı aktifleştirmelisiniz. "
  
  },
  forgot: {
    title: 'Parolamı Unuttum',
    subtitle: 'Aşağıdaki alana kayıtlı e-posta adresinizi giriniz. Parolanızı sıfırlamak için e-posta gönderilecektir.',
    email: 'E-Posta',
    button: 'Parolamı Sıfırla',
    backtosign: 'Giriş Yap'
  },
  userProfile: {
    userInformation: "Kullanıcı Bilgileri",
    personalInformation: "KİŞİSEL BİLGİLERİM",
    changePassword: "PAROLA DEĞİŞTİRME",
    myContents: "İÇERİKLERİM",
    tabSubscription: "ABONELİKLERİM", 
    name: "Ad",
    surname: "Soyad",
    username: "Kullanıcı Adı",
    email: "E-mail",
    ldapUserName: "LDAP Kullanıcı Adı",
    union: "Birim",
    title: "Unvan",
    editAvatar: "Avatarı Düzenle",
    currentPassword: "Mevcut Parola",
    newPassword: "Yeni Parola",
    confirmNewPassword: "Yeni Parola ( Doğrulama )",
    type: "Tür",
    contentName: "İçerik Adı",
    role: "Rol",
    location: "Konum",
    passwordRules: {
      minCharacters: "Parola minimum 8 karakter olmalı ve büyük küçük harf ve sayı içermelidir.",
      mismatch: "Parola Uyuşmamaktadır",
      invalidPassword: "Geçersiz Parola",
      enterDifferentPassword: "Farklı Bir Parola Girin",
    },
    contents: {
      type: "Tür",
      contentName: "İçerik Adı",
      role: "Rol",
      location: "Konum"
    },
    subscription: {
      name: "Abonelik Adı",
      projectName: "Proje Adı",
      panelName: "Panel Adı",
      period: "Gönderim Periyodu",
      creator: "Oluşturan",
      subscType: "Abonelik Türü",
      status: "Durum",
      sendStatus: "Bildirim"
    }
  },

  success: {
    successfullyPasswordChanged: "Şifreniz Başarılı Bir Şekilde Değiştirildi",    
    modelUpdated: "Model başarılı bir şekilde güncellendi!",
    userDataUpdated: "Kullanıcı bilgileri güncellendi!",
    userGroupsUpdated: "Kullanıcı grubu güncellendi!",
    validSqlStatement: "Geçerli SQL İfadesi",
    inactivateUserStatus: "Kullanıcı pasif yapıldı.",
    activateUserStatus: "Kullanıcı aktif yapıldı.",
    passwordChanged: "Parolanız başarılı bir şekilde güncellendi! Yeni parolanız ile sisteme giriş yapınız.",
    inactivateGroupStatus: "Grup pasif yapıldı.",
    activateGroupStatus: "Grup aktif yapıldı.",
    updating: "Kayıt güncellendi!",
    newGroupSuccess: "Yeni grup eklendi!",
    inserted: "Kayıt yapıldı!",
    deleted: "Kayıt silindi!",
    groupUsersUpdated: "Grup kullanıcıları güncellendi!",
    inactivateSubsStatus: "Abonelik pasif yapıldı.",
    activateSubsStatus: "Abonelik aktif yapıldı.",
    rightInserted: "Yeni yetki tanımlandı.",
    rightDeleted: "Yetki silindi.",
    sendMailSuccess: "Aktivasyon maili gönderilmiştir.",
    sendMailUserSuccess: "Aktivasyon maili e-posta adresinize gönderilmiştir. Lütfen gelen/spam kutunuzu kontrol ediniz.",
    subscriptionCreated: "Abonelik başarılı bir şekilde oluşturuldu!",
    sendFileSuccess: "Dosya e-posta adresinize gönderilmiştir."
  },
  dashboard: {
    categories: "Kategoriler",
    panels: "Paneller",
    reports: "Raporlar",
    newCategory: "Yeni Kategori",
    newPanel: "Yeni Panel",
    newReport: "Yeni Rapor",
    update: "Güncelle",
    delete: "Sil",
    subscribe: "Abonelik Oluştur",
    showSubscription: "Abonelikleri Göster"
  },
  project: {
    projects: "Projeler",
    newProject: "Yeni Proje",
    noProjectInfo: "Görme yetkiniz olan herhangi bir proje yoktur. Lütfen Sistem Yöneticinizle iletişime geçiniz!",
    noContentInfo: "Gösterilecek içerik bulunmamaktadır. Lütfen sistem yöneticinizle görüşünüz!",
    dublicateProjectName: "Aynı isimde proje bulunmaktadır. Lütfen proje ismini değiştiriniz!"
  },
  menu: {
    search: "Ara",
    logout: "Çıkış",
    profile: "Profil",
    home: "Ana Sayfa",
    projects: "Projeler",
    general: "Genel",
    management: "YÖNETİM",
    system: "SİSTEM AYARLARI",
    openIdManagement: "OpenID Konfigürasyonu",
    auditLogPage: "Denetim Kayıtları",
    dataSettings: "Veri İndirme Ayarları",
    rawDataDownloadPage: "Ham Veri İndirme",
    title: "Veri Görselleştirme Platformu",
    data: "VERİ",
    catalogs: "Kataloglar",
    userManagement: "Kullanıcı Yönetimi",
    groupManagement: "Grup Yönetimi",
    projectCategoryAuth: "Proje ve Kategori Yetkileri",
    subscriptionManagement: "Abonelik Yönetimi",
    scheduleManagement: "Zaman Çizelgeleri Yönetimi",
    projectMembers: "Proje Üyeleri",
    authorization: "Yetkilendirme",
    about: "Hakkında",
    noResultsFound: "Sonuç bulunamadı!"
  },
  categoryCreator: {
    dialogTitleUpdate: "Kategori Güncelleme",
    dialogTitleCreate: "Yeni Kategori",
    title: "Başlık",
    description: "Açıklama",
    folderColor: "Klasör Rengi",
  },
  panelCreator: {
    dialogTitleUpdate: "Panel Güncelleme",
    dialogTitleCreate: "Yeni Panel",
    title: "Başlık",
    description: "Açıklama",
    link: "Bağlantı",
    refreshTime: "Yenileme Zamanı",
    timeType: "Zaman Türü",
    theme: "Tema",
    backgroundColor: "Arka Plan Rengi",
    dark: "Koyu",
    light: "Açık",
    darkSequential: "Koyu Ardışık",
    darkPastel: "Koyu Pastel",
    lightSequential: "Açık Ardışık",
    lightPastel: "Açık Pastel",
    second: "Saniye",
    minute: "Dakika",
    hour: "Saat",
    maxRefreshTime: "Yenileme zamanı 1-100 arasında olmalıdır!",
    panelVisibility: "Panel Görünürlüğü"
  },
  reportCreator: {
    dialogTitleUpdate: "Rapor Güncelleme",
    dialogTitleCreate: "Yeni Rapor",
    title: "Başlık",
    description: "Açıklama",
    link: "Bağlantı",
    permissionGroups: "Yetki Grupları",
    refreshTime: "Yenileme Zamanı"
  },
  projectCreator: {
    dialogTitleCreate: "Yeni Proje",
    dialogTitleView: "Proje Bilgileri",
    dialogTitleUpdate: "Proje Güncelleme",
    projectInfo: "Proje Bilgileri",
    dataSourceSelection: "Veri Kaynağı Seçimi",
    dataSourceSetting: "Veri Kaynağı Ayarları",
    projectName: "Proje Adı",
    projectDefinition: "Proje Tanımı",
    projectAvatar: "Proje İkonu",
    title: "Başlık",
    description: "Açıklama",
    projectIcon: "Proje İkonu",
    projectManager: "Proje Yöneticisi",
    dataSourceType: "Veri Kaynağı Türü ",
    host: "Sunucu Adresi",
    port: "Port",
    databaseName: "Veritabanı İsmi",
    userName: "Kullanıcı Adı",
    password: "Parola",
    datasources: "Kayıtlı Veri Kaynakları",
    datasourceName: "Veri Kaynağı Adı",
    datasourceDescription: "Açıklama",
    completeDataWarning: "Lütfen tüm zorunlu alanları doldurunuz!",
    deleteAvatarTooltip: "İkonu Sil",
    viewTooltip: "Göster",
    updateTooltip: "Güncelle",
    deleteTooltip: "Sil",
    infoTooltip: "Açıklama",
    projectNameCharacterSize: "Proje ismi maximum 128 karakter olmalıdır."

  },
  widget: {
    delete: "Sil",
    edit: "Düzenle",
    previousChart: "Önceki Görsel",
    nextChart: "Sonraki Görsel",
    sortByLabel: "Etikete Göre Sırala",
    sortByMeasure: "Ölçüte Göre Sırala",
    changeDateMode: "Tarih Modunu Değiştir",
    copyToClipboard: "Clipboarda Kopyala",
    saveAsImage: "Resim Olarak Kaydet",
    saveSummaryData: "Özet Veriyi İndir",
    saveRawData: "Ham Veriyi İndir",
    fullScreen: "Ekranı Genişlet",
    fullScreenCompress: "Ekranı Küçült",
    setAsCover: "Panel Kapağı Olarak Ayarla",
    saveAsTemplate: "Şablon Olarak Kaydet",
    tag: "Etiket",
    description: "Açıklama",
    filters: "Filtreler",
    noDataInfo: "Gösterilecek Veri Bulunmamaktadır"
  },
  tagCreator: {
    dialogTitleCreate: "Yeni Şablon",
    tag: "Şablon İsmi"
  },
  infoCreator: {
    dialogTitleCreate: "Yeni Açıklama",
    description: "Açıklama"
  },
  widgetToolbar: {
    widgetCreate: "Yeni Görsel",
    filters: "Filtreler"
  },
  toolbar: {
    notifications: "Bildirimlerim",
    delete: "Sil"
  },
  filter: {
    date: "Tarih",
    "date-range": "Tarih Aralığı",
    "multi-select": "Çoklu Seçim",
    "single-select": "Tekli Seçim",
    "preset-date": "Ön Tanımlı Tarih",
    lastOneHour: "Son Bir Saat",
    lastOneDay: "Son Bir Gün",
    lastOneWeek: "Son Bir Hafta",
    lastOneMonth: "Son Bir Ay",
    lastThreeMonth: "Son Üç Ay",
    lastSixMonth: "Son Altı Ay",
    lastOneYear: "Son Bir Yıl",
    nextOneMonth: "Gelecek Bir Ay",
    nextThreeMonth: "Gelecek Üç Ay",
    nextSixMonth: "Gelecek Altı Ay",
    nextOneYear: "Gelecek Bir Yıl",
    sinceToday: "Bugün",
    sinceThisWeek: "Bu Hafta",
    sinceThisMonth: "Bu Ay",
    sinceThisYear: "Bu Yıl",
    lastFiveYears: "Son Beş Yıl",
    lastTenYears: "Son On Yıl",
    lastOneCalendarMonth: "Son Bir Takvim Ayı",
    lastThreeCalendarMonth: "Son Üç Takvim Ayı",
    lastSixCalendarMonth: "Son Altı Takvim Ayı",
    lastOneCalendarYear: "Son Bir Takvim Yılı",
    firstQuarter: "Birinci Çeyrek",
    secondQuarter: "İkinci Çeyrek",
    thirdQuarter: "Üçüncü Çeyrek",
    fourthQuarter: "Dördüncü Çeyrek",

  },
  filterSetting: {
    dialogTitle: "Filtre Ayarları",
    addFilter: "Filtre Ekle",
    deleteFilter: "Filtreyi Sil",
    type: "TİP",
    label: "Etiket",
    filterLabelCharacterSize : "Max. 20 karakter",
    "model/table": "Model / Tablo",
    "dimension/column": "Boyut / Alan",
    defaultValues: "Varsayılan Değerler",
    date: "Tarih",
    "date-range": "Tarih Aralığı",
    "multi-select": "Çoklu Seçim",
    "single-select": "Tekli Seçim",
    "preset-date": "Ön Tanımlı Tarih"
  },
  widgetCreator: {
    templateSelection: "Şablon Seçimi",
    chartTypeSelection: "Grafik Tipi Seçimi",
    dataOptionsSelection: "Veri Ayarları Seçimi",
    visualOptionsSelection: "Görsel Ayarları Seçimi",
    allCharts: "TÜMÜ",
    graphicCharts: "GRAFİKLER",
    cardCharts: "KARTLAR",
    mapCharts: "HARİTALAR",
    warning: "* Lütfen grafik tipi seçiniz!",
    infoTemplate: "Kayıt Adresi"
  },
  dataOptionsTab: {
    catalog: "Katalog",
    model: "Model",
    tableColumns: "Tablo Kolonları",
    tableColumnsOrder: "Tablo Kolon Sıralaması",
    valueField: "Ölçüt",
    valueFieldLine: "2. Ölçüt",
    labelField: "Etiket",
    parentRegion: "Üst Bölge",
    fromLabelField: "Çıkış Etiketi",
    toLabelField: "Varış Etiketi",
    groupField: "Grup",
    fact: "Model",
    label: "Etiket",
    dimensions: "Boyutlar",
    measures: "Ölçütler",
    measure: "Ölçüt",
    columns: "Alanlar",
    models: "Modeller",
    imageField: "Resim",
    textField: "Yazı",
    mapType: "Harita Tipi",
    routeType: "Hedef Tipi",
    latitudeField: "Enlem Alanı",
    longitudeField: "Boylam Alanı",
    fromLongitudeField: "Çıkış Boylam",
    fromLatitudeField: "Çıkış Enlem",
    toLongitudeField: "Varış Boylam",
    toLatitudeField: "Varış Enlem",
    targetField: "Hedef Ölçüt",
    targetValue: "Hedef Değer",
    maxValue: "Maximum Değer",
    maxValuePercentage: "Maximum Değer Yüzdesi",
    geoDataFormat: "Coğrafi Veri Formatı",
    geoData: "Coğrafi Veri",
    link: "Bağlantı",
    europe: "Avrupa Haritası",
    districts: "Türkiye İlçe Haritası",
    cities: "Türkiye İl Haritası",
    unions: "Bölge Birliği Haritası",
    atlasMap: "Harita GM",
    openStreet: "Open Street",
    limit: "Veri Limiti",
    protectedFields: "Korunan Alanlar",
    dateMode: "Tarih Formatı",
    operator: "Operatör",
    value: "Değer",
    filterPanelTitle: "Filtreler",
    geoJSON: "GeoJSON",
    WKT: "WKT",
    warning: "* Lütfen gerekli alanları doldurunuz!",
    minLimitWarning: "Limit değeri 1 ile 999.999.999 arasında olmalıdır!",
    maxPercentValue: "Maximum değer yüzdesi 1-100 arasında olmalıdır!",
    maxValueWarning: "Maximum değer 1-1.000.000.000 arasında olmalıdır!"
  },
  visualOptionsTab: {
    basicOptions: "Temel Ayarlar",
    backgroundColor: "Arka Plan Rengi",
    backgroundColorOpacity: "Arka Plan Opaklığı",
    axisPointerType: "Eksen İşaretçisi Tipi",
    shadow: "Gölge",
    line: "Çizgi",
    cross: "İşaretçi",
    isStacked: "Stack Bar",
    colors: "Renkler",
    color: "Renk",
    titleOptions: "Başlık Ayarları",
    alignment: "Hizalama",
    title: "Başlık",
    topTitle: "Üst Başlık",
    bottomTitle: "Alt Başlık",
    show: "Göster",
    link: "Bağlantı",
    textColor: "Yazı Rengi",
    titleColor: "Başlık Rengi",
    subTitleColor: "Alt Metin Rengi",
    left: "Sol",
    right: "Sağ",
    top: "Üst",
    bottom: "Alt",
    subtitle: "Alt Metin",
    autoSubtext: "Otomatik Alt Metin",
    fontFamily: "Yazı Tipi Ailesi",
    fontSize: "Yazı Tipi Boyutu",
    fontWeight: "Yazı Tipi Ağırlığı",
    fontStyle: "Font Stili",
    legendOptions: "Lejant Ayarları",
    showLegends: "Lejantları Göster",
    legendType: "Lejant Tipi",
    legendColor: "Lejant Renk Seçimi",
    plain: "Sade",
    scroll: "Kaydırılabilir",
    orient: "Yön",
    vertical: "Dikey",
    horizontal: "Yatay",
    visualMap: "Görsel Harita",
    calculable: "Hesaplanabilir",
    formatter: "Formatlayıcı",
    width: "En",
    height: "Boy",
    tooltip: "Bilgi Kutusu",
    item: "Parça",
    axis: "Eksen",
    confine: "Sınırla",
    trigger: "Tetikleyici",
    borderOptions: "Kenar Ayarları",
    solid: "Solid",
    dotted: "Dotted",
    dashed: "Dashed",
    double: "Double",
    groove: "Groove",
    ridge: "Ridge",
    borderType: "Kenar Tipi",
    borderColor: "Kenar Rengi",
    borderRadius: "Kenar Yarıçapı",
    borderWidth: "Kenar Kalınlığı",
    emphasisOptions: "Vurgu Ayarları",
    filledColor: "Dolgu Rengi",
    shadowColor: "Gölge Rengi",
    shadowBlur: "Gölge Bulanıklığı",
    sizeOptions: "Boyut Ayarları",
    xAxisOptions: "X Ekseni Ayarları",
    showXAxis: "X Eksenini Göster",
    axisType: "Eksen Tipi",
    category: "Etiket",
    value: "Değer",
    automaticScale: "Otomatik Boyutlandır",
    axisLine: "Eksen Çizgisi",
    axisLineColor: "Çizgi Rengi",
    axisLineType: "Çizgi Tipi",
    axisTick: "Eksen İşareti",
    axisTickColor: "İşaret Rengi",
    axisTickType: "Tipi",
    splitLine: "Ayırma Çizgisi",
    splitLineColor: "Çizgi Rengi",
    splitLineType: "Çizgi Tipi",
    axisLabel: "Eksen Metni",
    axisLabelColor: "Metin Rengi",
    rotate: "Eğiklik",
    labelLength: "Karakter Sayısı",
    yAxisOptions: "Y Ekseni Ayarları",
    showYAxis: "Y Eksenini Göster",
    showSumLine: "Toplam Çizgisini Göster",
    yAxis2: "2. Y Ekseni",
    equalAxis: "Eşit Eksen",
    axisFormatter: "Eksen Formatlayıcı",
    tooltipFormatter: "Bilgi Kutusu Formatlayıcı",
    axisLineShow: "Eksen Çizgisi Göster",
    axisLineWidth: "Eksen Çizgisi Eni",
    axisTickShow: "Eksen İşareti Göster",
    splitLineShow: "Ayırma Çizgisi Göster",
    axisTickWidth: "Eksen İşareti Genişliği",
    splitLineWidth: "Ayırma Çizgisi Genişliği",
    axisLabelShow: "Eksen Etiketini Göster",
    pieOptions: "Pasta Ayarları",
    outsideRadius: "Dış Yarıçap",
    insideRadius: "İç Yarıçap",
    centerX: "Merkez X Konumu",
    centerY: "Merkez Y Konumu",
    showLabels: "Etiketleri Göster",
    showPercentLabels: "Yüzdelikleri Göster",
    radialOptions: "Radyal Ayarları",
    brightnessBlur: "Parlaklık Dağınıklığı",
    brightnessColor: "Parlaklık Rengi",
    showPercentage: "Yüzdeyi Göster",
    labelColor: "Etiket Rengi",
    radialColor: "Radyal Rengi",
    showLabel: "Etiketi Göster",
    innerPieOptions: "İç Pasta Ayarları",
    outerPieOptions: "Dış Pasta Ayarları",
    geoOptions: "Coğrafi Ayarlar",
    roam: "Gezinme",
    multiSelection: "Çoklu Seçim",
    routeColor: "Yol Rengi",
    routeWidth: "Yol Kalınlığı",
    showOnHover: "Üzerine Gelince Göster",
    alwaysShow: "Her Zaman Göster",
    labelOptions: "Etiket Ayarları",
    areaColor: "Alan Rengi",
    mapStyle: "Harita Stilleri",
    weight: "Kalınlık",
    opacity: "Opaklık",
    fillColor: "Dolgu Rengi",
    textAlign: "Yazı Konumu",
    valueStyle: "Değer Ayarları",
    alignContentVertical: "Dikey Hizalama",
    alignContentHorizontal: "Yatay Hizalama",
    textStyle: "Yazı Stili",
    iconStyle: "Resim Stili",
    iconColor: "Resim Rengi",
    iconSize: "Resim Boyutu",
    subtext: "Alt Metin",
    position: "Pozisyon",
    topLabelStyle: "Üst Başlık Ayarları",
    bottomLabelStyle: "Alt Başlık Ayarları",
    tableChartOptions: "Tablo Görsel Ayarları",
    showTableHeader: "Tablo Başlığını Göster",
    headerDirection: "Başlık Yönü",
    tableView: "Tablo Görünümü",
    scrollTable: "Kaydır",
    pagination: "Sayfalandır",
    resizeCell: "Hücreleri Boyutlandır",
    resizeContent: "İçeriğe göre otomatik ayarla",
    resizeEqual: "Sütunları eşit dağıt",
    customize: "Sütun Genişliğini Özelleştir",
    customizeColumnInfo: "Sütun genişliğini 'Tablo Alanları Ayarları' bölümünde, her sütun için genişlik oranı vererek belirleyebilirsiniz.",
    showColumnSum: "Sütun Toplamını Göster",
    showValues: "Değerleri Göster"
    
  },
  tableChartVisualOptions: {
    visualOptions: "Tablo Alanları Ayarları",
    columnTitle: "Görünen Başlık",
    columnView: "Sütun Görünümü",
    text: "Metin",
    number: "Sayısal",
    money: "Parasal",
    star: "Yıldız",
    progress: "İlerleme Çubuğu",
    date: "Tarih",
    tickCross: "İkili Gösterim (Tick/Cross)",
    textColor: "Yazı Rengi",
    filledColor: "Dolgu Rengi",
    progressColor: "İlerleme Çubuğu Rengi",
    cellAlignment: "Hücre Hizalama",
    headerAlignment: "Başlık Hizalama",
    numberFormat: "Sayı Formatı",
    currencyUnit: "Para Birimi",
    dateFormat: "Tarih Formatı",
    startMaxValue: "Maksimum Değer",
    starCount: "Yıldız Sayısı",
    minStarCountWarning: "En az 2 girmelisiniz!",
    maxStarCountWarning: "En fazla 10 girmelisiniz!",
    columnWidthRatio: "Genişlik Oranı (1-5)",
    minRatioWarning: "En az 1 girmelisiniz!",
    maxRatioWarning: "En fazla 5 girmelisiniz!"
  },
  catalog: {
    catalogs: "Kataloglar",
    name: "Katalog İsmi",
    description: "Açıklama",
    dialogTitleUpdate: "Katalog Güncelleme",
    dialogTitleCreate: "Yeni Katalog Oluşturma",
    newCatalog: "Yeni Katalog",
    dsTitle: "{0} Veri Kaynağı Bilgileri",
    dsType: "Veri Kaynağı Türü : ",
    dsHostName : "Hostname : ",
    dsPort: "Port : ",
    dsDbName: "Veritabanı Adı : ",
  },
  model: {
    description: "Açıklama",
    newModel: "Yeni Model",
    models: "Modeller",
    model: "Model",
    modelName: "Model Adı",
    schema: "Şema",
    table: "Tablo",
    content: "Modeli Kullanan İçerik",
    contentType: "Tür",
    contentName: "İçerik Adı",
    contentPath: "Konum",
    contentDialogTitle: "{0} Modelini Kullanan İçerikler",
    contentCardTitle: "Modeli kullanan İçerikler",
    copySql: "SQL'i Kopyala",
    copySqlInfo: "SQL Kopyalandı!"
  },
  modelCreator: {
    tableSelection: "Tablo Seçimi/Sql Editör",
    expansionSelection: "İlişkilendirme",
    measureDimensionSelection: "Ölçütler/Boyutlar",
    modelComplete: "Model Onayı",
    dataPreview: "Veri Önizleme",
    previewTooltip: "Veri önizleme için sadece 20 kayıt gösterilmektedir.",
    fields: "Alanlar",
    fieldName: "Alan Adı",
    fieldType: "Alan Türü",
    fieldSelection: "Alan Seçimi",
    expansion: "İLİŞKİLENDİRME",
    schema: "Şema",
    table: "Tablo",
    expansionColumn: "İlişkilendirilecek Alan",
    addedColumns: "Eklenecek Alanlar",
    create: "Oluştur",
    measures: "Ölçütler",
    dimensions: "Boyutlar",
    measure: "Ölçüt",
    dimension: "Boyut",
    function: "FONKSİYON",
    label: "ETİKET",
    sum: "Toplam",
    average: "Ortalama",
    maximum: "Maksimum",
    minimum: "Minimum",
    median: "Medyan",
    standardDeviation: "Standart Sapma",
    variance: "Varyans",
    count: "Sayma",
    distinctCount: "Farklıları Sayma",
    reload: "Tabloyu Güncelle",
    modelName: "Model Adı",
    definition: "Açıklama",
    operation: "İşlem",
    convertToDimension: "Boyuta Çevir",
    convertToMeasure: "Ölçüte Çevir",
    tableSelectionLabel: "TABLO SEÇİMİ",
    sqlEditor: "SQL EDİTÖR",
    sqlField: "Sql Editör",
    sqlCheckButton: "SQL Kontrol Et",
    max50Characters: "Maximum 50 karakter"
  },
  editModel: {
    updateModel: "Modeli Güncelle",
    copyModel: "Modeli Kopyala",
    deleteModel: "Modeli Sil",
    fieldSelection: "Seçim",
    fieldName: "Alan Adı",
    fieldLabel: "Etiket",
    function: "Fonksiyon",
    operations: "İşlemler",
    expansion: "İLİŞKİLENDİRME",
    schema: "Şema",
    table: "Tablo",
    expansionColumn: "İlişkilendirilecek Alan",
    addedColumns: "Eklenecek Alanlar",
    create: "Oluştur",
    formula: "Formül",
    convertToDimension: "Alanı Boyuta Çevir",
    convertToMeasure: "Alanı Ölçüte Çevir",
    addFormula: "Formül Ekle",
    formulaDialogTitle: "Yeni Ölçüt",
    measures: "Ölçütler",
    dimensions: "Boyutlar",
    expansionDimension : "İlişkilendirilen Boyutlar",
    expansionMeasure : "İlişkilendirilen Ölçütler",
    modelSql : "Model SQL"
  },
  viewModel:{
    dialogTitleView: "Model Bilgileri",
    schemaName: "Şema Adı",
    tableName: "Tablo Adı",
    modelName: "Model Adı",
    modelDescription: "Model Açıklaması",

  },
  userManagement: {
    newUser: "Yeni Kullanıcı",
    name: "Ad",
    surname: "Soyad",
    email: "E-posta",
    createdDate: "OLUŞTURMA TARİHİ",
    projectMembership: "PROJE ÜYELİĞİ",
    groupMembership: "GRUP ÜYELİĞİ",
    status: "Durum",
    groupDialogTitle: "{0} Kullanıcısının Grup Üyelikleri",
    projectDialogTitle: "{0} Kullanıcısının Proje Üyelikleri",
    groupName: "Grup Adı",
    groupMemberCount: "Grup Üye Sayısı",
    newRecord: "Yeni Kayıt",
    totalRecords: "Toplam {0} kayıt",
    filter: "Filtre",
    active: "Aktif Kullanıcılar",
    passive: "Pasif Kullanıcılar",
    new: "Yeni Kullanıcılar",
    all: "Tümü"

  },
  userUpdate: {
    tabPersonal: "KULLANICI BİLGİLERİ",
    tabGroups: "GRUP ÜYELİKLERİ",
    tabRights: "KULLANICI YETKİLERİ",
    name: "Adı",
    surname: "Soyadı",
    userName: "Kullanıcı Adı",
    email: "E-posta Adresi",
    userType: "Kullanıcı Tipi",
    status: "Durum",
    sendEmailForActivation: "Aktivasyon Linki Gönder",
    allGroups: "Tüm Gruplar",
    userGroups: "Kullanıcının Grupları",
    addAll: "Tümünü Ekle",
    addToGroup: "Gruba Ekle",
    deleteAll: "Tümünü Çıkar",
    deleteFromGroup:"Gruptan Çıkar",
    find: "Ara",
    userRole: "Kullanıcı Rolü :",
    groupRole: "Grup Rolü :",
    groupName: "Grup Adı :",
    permissionSource: "Yetki Kaynağı :",
    userRightsTitle: "Kullanıcı Yetki Detayları",
    selectProjectOrCategory: " Proje/Kategori seçiniz",
    selectedProject: "Seçili Proje :",
    selectedCategory: "Seçili Kategori :",
    deleteRight: "Yetkiyi Sil",
    newRight: "Yeni Yetki Tanımla",
    EDITOR: "Editör",
    VISUAL_EDITOR: "Görsel Editör",
    VISITOR: "Görüntüleyici",
    PROJECT_ADMIN: "Proje Yöneticisi",
    Project: "Proje",
    Category: "Kategori",
    CATEGORY: "KATEGORİ",
    PROJECT: "PROJE"
    
  },
  userCreator: {
    name: "Adı",
    surname: "Soyadı",
    username: "Kullanıcı Adı",
    email: "E-posta Adresi",
    password: "Şifre",
    dialogTitleUserCreate: "Yeni Kullanıcı Tanımlama",
    userType: "Kullanıcı Türü",
    DB: "Veritabanı Kullanıcısı",
    LDAP: "LDAP Kullanıcısı",
    passwordRules: {
      passwordCharacterSize: "En az 8 karakter giriniz",
      passwordCharacterControl: "Parola en az 8 karakter olmalı ve büyük küçük harf ve sayı içermelidir.",
      mismatch: "Yeni parolanız uyuşmamaktadır!"
    },
    emailCheck: "E-posta adresi geçersiz!",
    dataValidation: "Girilen bilgileri kontrol ediniz!",
    emailIsNotDomainList: "Girdiğiniz eposta domain listesinde değil!"
  },
  passwordPage: {
     title: "Parola Güncelleme",
     subtitle: "Size gönderilen parolayı eski parola alanına, yeni parolanızı yeni parola alanlarına giriniz.",
     oldPassword: "Eski Parola",
     newPassword1: "Yeni Parola",
     newPassword2: "Yeni Parola (Tekrar)",
     changePassword: "Parolamı Güncelle",
     passwordInvalid: "Girilen parola yanlış. Lütfen tekrar deneyiniz."
  },
  activationMailPage: { 
    title: "Kullanıcı Aktivasyon Maili Gönderme",
    message: "Aktivasyon linkinizin süresi dolmuştur. Yeniden aktivasyon maili almak için aşağıdaki butona tıklayınız.",
    sendMail: "Aktivasyon Maili Gönder",
    title2: "LDAP Kullanıcı Aktivasyon Sayfası",
    userActivatedSuccesfully: "Kullanıcı başarılı bir şekilde aktifleştirilmiştir."
  },
  groupManagement: {
    groupManagement: "Grup Yönetimi",
    newGroup: "Yeni Grup",
    groupName: "Grup Adı",
    userCount: "Kullanıcı Sayısı",
    createdDate: "OLUŞTURMA TARİHİ",
    status: "Durum",  
    newRecord: "Yeni Kayıt",
    totalRecords: "Toplam {0} kayıt",
    filter: "Filtre",
    active: "Aktif Gruplar",
    passive: "Pasif Gruplar",
    all: "Tümü",
    userDialogTitle: "{0} Grubu Kullanıcıları",
    name: "Ad",
    surname: "Soyad",
    email: "E-posta",
    groupAttendanceDate: "Gruba Katılma Tarihi"
  },
  groupCreator: {
    dialogTitle: "Yeni Grup Oluşturma",
    groupName: "Grup Adı",
  },
  groupUpdate: {
    tabGroupData: "GRUP BİLGİLERİ",
    tabGroupUsers: "GRUP ÜYELERİ",
    tabGroupRights: "GRUP YETKİLERİ",
    groupName: "Grup Adı",
    allUsers: "Tüm Kullanıcılar",
    groupUsers: "Grup Kullanıcıları",
    addAll: "Tümünü Ekle",
    addToGroup: "Gruba Ekle",
    deleteAll: "Tümünü Çıkar",
    deleteFromGroup:"Gruptan Çıkar",
    find: "Ara",
    groupRightsTitle: "Yetki Detayları",
    selectedProject: "Seçili Proje :",
    selectedCategory: "Seçili Kategori :",
    selectProjectOrCategory: " Proje/Kategori seçiniz",
    deleteRight: "Yetkiyi Sil",
    newRight: "Yeni Yetki Tanımla",
    role: "Rolü :",
    permissionSource: "Yetki Kaynağı :",
    rightCreator: {
      dialogTitleCreate: "Yeni Yetki Tanımlama",
      project: "Proje Seçiniz",
      levelMessage: "Yetki vermek istediğiniz seviyeyi seçiniz.",
      roleMessage: "Seçilen yetki kaynağı ve alt seviyelerinde kullanılmak üzere bir rol belirleyiniz.",
      role: "Rol seçiniz",
      EDITOR: "Editör",
      VISUAL_EDITOR: "Görsel Editör",
      VISITOR: "Görüntüleyici",
      levelMessageWarning: "Lütfen yetki vermek istediğiniz seviyeyi seçiniz!"
    }
  },
  projectCategoryAuth: {
    projectSelection: "Proje Seçiniz",
    newRight: "Yeni Yetki Tanımla",
    type: "Tür",
    userGroupName: "Kullanıcı/Grup Adı",
    name: "Ad",
    surname: "Soyadı",
    role: "Rol",
    permissionSource: "İzin Kaynağı",
    rightCreationDate: "Yetki Verilme Tarihi",
    operations: "İşlemler",
    newRightButton: "Yeni Yetki Tanımlama",
    deleteRight: "Yetkiyi Sil",
    projectManager:"Proje Yöneticisi",
    editor: "Editör",
    viewer: "Görüntüleyici",
    userGroupPermissionText: "Kullanıcı/Grup Yetkileri",
    categoryPermissionText: "Seçili Kategoride Verilen Yetkiler",
    projectPermissionText: "Projeden veya Üst Kategorilerden Aktarılan Yetkiler",
  },
  userGroupRightCreator: {
    name: "Ad",
    surname: "Soyad",
    username: "Kullanıcı Adı",
    groupName: "Grup Adı",
    userCount: "Kullanıcı Sayısı",
    selectedProject: "Seçilen Proje/Kategori",
    tabUserList: "KULLANICI LİSTESİ",
    tabGroupList: "GRUP LİSTESİ",
  },
  projectMembers: {
    title: "{0} Projesi Üyeleri",
    type: "Tür",
    userGroupName: "Kullanıcı/Grup Adı",
    name: "Ad",
    surname: "Soyad",
    status: "Durum",
    active: "Aktif",
    passive: "Pasif",
    initialized: "Yeni Kayıt",
    userPermissionTitle: "{0} Kullanıcısı Yetki Akışı",
    groupPermissionTitle: "{0} Grubu Yetki Akışı",
    USER: "Kullanıcı",
    GROUP: "Grup",
  },
  projectAuth: {
    title: "{0} Projesi Yetkilendirme",
    newRight: "Yeni Yetki Tanımla",
  },
  errorPages: {
    error404Msg: "Aradığın içeriğe şu an ulaşılamıyor.",
    error500Msg: "Sistem hatası oluştu. Lütfen sistem yöneticinize başvurunuz.",
    homePage: "Ana Sayfa"
  }, 
  searchPage: {
    pageTitle: "Arama Sonuçları",
    filterType: "İçerik Türü",
    filterCreated: "Oluşturan",
    filterCreateDate: "Oluşturma Tarihi",
    filterUpdateDate: "Güncelleme Tarihi",
    project: "Proje",
    category: "Kategori",
    panel: "Panel",
    report: "Rapor",
    model: "Model",
    resultText: "{0} metnini içeren {1} sonuç gösteriliyor.",
    type: "İçerik Türü",
    title: "İçerik Adı",
    creator: "Oluşturan",
    createdDate: "Oluşturma Tarihi",
    updatedDate: "Güncelleme Tarihi",
    path: "Konum",
  }, 
  subscription: {
    titleCreate: "Abonelik Oluşturma",
    titleUpdate: "Abonelik Güncelleme",
    name: "Abonelik Adı",
    subscriptionNameCharacterSize: "Abonelik adı maksimum 50 karakter olmalıdır.",
    task: "Konu",
    subscriptionTaskCharacterSize: "Konu maksimum 100 karakter olmalıdır.",
    message: "Mesaj",
    period: "Gönderim Periyodu",
    users: "Kullanıcılar",
    groups: "Gruplar"
  },
  subscriptionPage: {
    title: "Abonelik Yönetimi",
    name: "Abonelik Adı",
    projectName: "Proje Adı",
    panelName: "Panel Adı",
    period: "Gönderim Periyodu",
    creator: "Oluşturan",
    createdDate: "Oluşturma Tarihi",
    lastSendDate: "Son Gönderim Tarihi",
    status: "Durum",
    operations: "İşlemler",
    view: "Detay Gör",
    update: "Güncelle",
    delete: "Sil",
    active: "Aktif",
    passive: "Pasif",  
    totalRecords: "Toplam {0} kayıt"
  },
  subscriptionView: {
    subsViewTitle: "Abonelik Detayı",
    subsListTitle: "Panel Abonelikleri",
    name: "Abonelik Adı",
    panelName: "Panel Adı",
    task: "Konu",
    message: "Mesaj",
    period: "Gönderim Periyodu",
    creator: "Aboneliği Oluşturan",
    createdDate: "Oluşturma Tarihi",
    status: "Abonelik Durumu",
    users: "Kullanıcılar",
    groups: "Gruplar"
  },
  scheduleManagement: {
    title: "Zaman Çizelgeleri Yönetimi",
    newSchedule: "Yeni Çizelge",
    scheduleName: "ÇİZELGE ADI",
    period: "SIKLIK",
    startDate: "BAŞLANGIÇ TARİHİ",
    endDate: "BİTİŞ TARİHİ",
    subscriptions: "KULLANAN ABONELİKLER",
    operations: "İŞLEMLER",
    update: "Güncelle",
    delete: "Sil",
    totalRecords: "Toplam {0} kayıt",
    subsDialogTitle: "{0} İsimli Çizelgeyi Kullanan Abonelikler",
    subsName: "ABONELİK ADI",
    project: "PROJE",
    creator: "ABONELİĞİ OLUŞTURAN",
    scheduleNameCharacterSize: "Çizelge adı en fazla 50 karakter olmalıdır!",
    scheduleCreator:{
      titleCreate: "Yeni Zaman Çizelgesi",
      titleUpdate: "Zaman Çizelgesi Güncelleme",
      scheduleName: "Çizelge Adı",
      period: "Sıklık",
      DAILY: "Günlük",
      WEEKLY: "Haftalık",
      MONTHLY: "Aylık",
      hour: "Saat",
      startDate: "Başlangıç Tarihi",
      endDate: "Bitiş Tarihi",
      daySelection: "Gün Seçimi",
      MONDAY: "Pazartesi",
      TUESDAY: "Salı",
      WEDNESDAY: "Çarşamba",
      THURSDAY: "Perşembe",
      FRIDAY: "Cuma",
      SATURDAY: "Cumartesi",
      SUNDAY: "Pazar"
    }
  },
  openId: {
    title: "OpenID Konfigürasyonu",
    url: "Url",
    clientId: "Client ID",
    realm: "Realm",
    redirectUrl: "Redirect Url",
    domainList: "OpenID Domain Listesi",
    domainNameCheck: "Domain ismi geçersiz!",
    delete: "Domain Sil",
    add: "Domain Ekle",
    domainUsedInUser: "Seçtiğiniz domain kullanıldığı için silinemez!"
  },
  auditLog: {
    mainActivityTitle: "Aktivite Ana Başlığı",
    subActivityTitle: "Aktivite",
    order: "Sıralama Kriteri",
    orderDirection: "Sıralama Yönü",
    ASC: "Artan Sırada",
    DESC: "Azalan Sırada",
    user: "Kullanıcı",
    project: "Proje",
    search: "Ara",
    clear: "Temizle",
    filter: "Arama Kriterleri",
    SUBSCRIPTION: "Abonelik",
    IMAGE: "Görsel",
    GROUP_OPERATIONS: "Grup İşlemleri",
    CATEGORY: "Kategori",
    AUTHENTICATION: "Kimlik Doğrulama",
    USER_OPERATIONS: "Kullanıcı İşlemleri",
    PANEL: "Panel",
    PROFILE: "Profil",
    PROJECTS: "Proje",
    CATALOG: "Katalog",
    DATA_MODEL: "Veri Modeli",
    TIMELINE: "Zaman Çizelgesi",
     
    SUBSCRIPTION_CREATED: "Abonelik Oluşturma",
    SUBSCRIPTION_UPDATE: "Abonelik Güncelleme",
    SUBSCRIPTION_DELETED: "Abonelik Silme",
    SUBSCRIPTION_ACTIVATED: "Abonelik Aktifleştirme",
    SUBSCRIPTION_DEACTIVATED: "Abonelik Pasifleştirme",
    SUBSCRIPTION_SEND_EMAIL: "Abonelik İçin E-posta Gönderimi",

    IMAGE_CREATE: "Görsel Oluşturma",
    IMAGE_UPDATE: "Görseli Düzenleme",
    IMAGE_DELETE: "Görsel Silme",
    IMAGE_SQLQUERY_CHANGE: "Görsel SQL Güncelleme",
    IMAGE_SUMMARY_DATA_DOWNLOAD: "Görsel Özet Verisini İndirme",
    IMAGE_PURE_DATA_DOWNLOAD: "Görsel Ham Verisini İndirme",

    GROUP_CREATED: "Grup Oluşturma",
    GROUP_UPDATED: "Grup Bilgisi Güncelleme",
    GROUP_ACTIVATED: "Grubu Aktif Yapma",
    GROUP_DEACTIVATED: "Grubu Pasif Yapma",
    ADD_PRIVILEGE_TO_GROUP: "Gruba Yetki Verme",
    REMOVE_PRIVILEGE_TO_GROUP: "Grubun Yetkisini Silme",
    //Gruba Kullanıcı Ekleme / Gruptan Kullanıcı Çıkarma
    
    CATEGORY_CREATE: "Kategori Oluşturma",
    CATEGORY_UPDATE: "Kategori Güncelleme",
    CATEGORY_DELETE: "Kategori Silme",

    LOGIN: "Uygulamaya Giriş",
    LOGOUT: "Uygulamadan Çıkış",
    LOGIN_BAD_CREDENTIAL: "Kullanıcı Adı veya Şifre Hatalı",
    LOGIN_USER_INITIAL: "Kullanıcı İlk Kayıt",

    USER_CREATED: "Kullanıcı Oluşturma",
    USER_DELETED: "Kullanıcı Silme",
    USER_UPDATE: "Kullanıcı Bilgisi Güncelleme",
    SIMPLE_USER_CREATED: "LDAP Kullanıcı Oluşturma",
    USER_ACTIVATED: "Kullanıcı Aktif Yapma",
    USER_DEACTIVATED: "Kullanıcı Pasif Yapma",
    USER_ADD_TO_GROUP: "Kullanıcının gruba eklenmesi",
    USER_REMOVE_FROM_GROUP: "Kullanıcının gruptan çıkarılması",
    USER_PRIVILEGE_ADD: "Kullanıcıya yetki verilmesi",
    USER_PRIVILEGE_REMOVE: "Kullanıcının yetkisinin kaldırılması",
    
    PANEL_CREATE: "Panel Oluşturma",
    PANEL_DELETE: "Panel Silme",
    PANEL_UPDATE: "Panel Güncelleme",
    PANEL_SAVE: "Panel Kaydetme",
    PANEL_FILTER_CREATE: "Panel Varsayılan Filtrelerini Oluşturma",
    PANEL_FILTER_UPDATE: "Panel Varsayılan Filtrelerini Güncelleme",
    PANEL_FILTER_DELETE: "Panel Varsayılan Filtrelerini Silme",
    PANEL_SUBSCRIPTION: "Panel Aboneliği Oluşturma",

    PROFILE_UPDATE: "Kullanıcı Bilgilerini Güncelleme",
    PROFILE_PASS_CHANGE: "Kullanıcı Parola Değişikliği",

    PROJECT_CREATE: "Proje Oluşturma",
    PROJECT_DELETE: "Proje Silme",
    PROJECT_UPDATE: "Proje Güncelleme",
    PROJECT_VISIT: "Projeye Giriş",

    CATALOG_CREATE: "Katalog Oluşturma",
    CATALOG_RENAME: "Katalog Yeniden İsimlendirme",
    CATALOG_DELETED: "Katalog Silme",
    DATAMODEL_CREATED: "Veri Modeli Oluşturma",
    DATAMODEL_UPDATE: "Veri Modeli Güncelleme",
    DATAMODEL_DELETED: "Veri Modeli Silme",

    TIMELINE_CREATE: "Zaman Çizelgesi Oluşturma",
    TIMELINE_UPDATE: "Zaman Çizelgesi Güncelleme",
    TIMELINE_DELETE: "Zaman Çizelgesi Silme",

    TARIH_SAAT: "Tarih ve Saat",
    ACTIVITE_ANABASLIK: "Aktivite Ana Başlığı",
    ACTIVITE: "Aktivite",
    KULLANICI: "Kullanıcı",
    PROJE: "Proje",
    ETKILENEN_OGE: "Etkilenen Öğe",
    IP: "IP",
    VERSION: "Versiyon",
    
    date:{
      startHour: "Başlangıç Saati",
      endHour: "Bitiş Saati",
      startDate: "Başlangıç Tarihi",
      endDate: "Bitiş Tarihi",
    },
    table: {
      dateTime: "Tarih ve Saat",
      mainActivity: "Aktivite Ana Başlığı",
      subActivity: "Aktivite",
      userName: "Kullanıcı",
      ip: "IP",
      version: "Versiyon"
    },
    totalRecords: "Toplam {0} kayıt",
  },
  dataSetting: {
    title: "Veri İndirme Ayarları",
    delimiter: "CSV Delimiter Türü",
    otherDelimiter: "Diğer",
    recordSize: "İndirilebilir Satır Sayısı",
    recordSizeWarningExcel: "Satır sayısı 1 ile 1.048.576 arasında olmalıdır!",
    recordSizeWarningCsv: "Satır sayısı 1 ile 10.000.000 arasında olmalıdır!",
    delimiterLengthWarning: "Delimiter uzunluğu en fazla 4 olabilir!",
    subTitle1: "Excel Dosyası Biçiminde İndirme Ayarları",
    subTitle2: "CSV Dosyası Biçiminde İndirme Ayarları",
  },
  rawData: {
    title: "Ham Veri İndirme",
    donwloadInfo: "İndirme Bilgileri",
    downloadFields: "İndirilecek Alanlar",
    xlsxRecordSize: "Excel Toplam Satır Sayısı",
    csvRecordSize: "CSV Toplam Satır Sayısı",
    fileFormat: "Dosya Formatı",
    delimiter: "Delimiter Türü",
    otherDelimiter: "Diğer",
    delimiterLengthWarning: "Delimiter uzunluğu en fazla 4 olabilir!",
    retrieveData: "Veriyi Getir",
    selectAll: "Hepsini Seç",
    dataTooLargeError: "Verinin indirme süresi uzun süreceği için dosya e-posta adresinize gönderilecektir."
  },
  // Vuetify components translations
  $vuetify: {
    noDataText: "Kayıt Bulunamadı!",
    datePicker: {
      itemsSelected: ""
    },
    dataTable: {
      itemsPerPageText: "Her Sayfadaki Kayıt Sayısı"
    },
    dataFooter: {
      pageText: "",
      itemsPerPageAll: "Hepsi"
    },
    dataIterator: {
      noResultsText: "Sonuç Bulunamadı!"
    },

  }
};
