import Vuetify from '../../plugins/vuetify'
import utilities from './../../utilities/utilities';
import Vue from 'vue';

export default {
    /**
     * Main Toast
     */
    showToast: (state, toast) => {
        const { color, timeout, message } = toast

        state.toast = {
            message,
            color,
            timeout,
            show: true
        }
    },
    hideToast: (state) => {
        state.toast.show = false
    },
    resetToast: (state) => {
        state.toast = {
            show: false,
            color: 'black',
            message: '',
            timeout: 3000
        }
    },

    /**
     * Theme and Layout
     */
    setGlobalTheme: (state, theme) => {
        Vuetify.framework.theme.dark = theme === 'dark'
        state.globalTheme = theme
    },
    setRTL: (state, isRTL) => {
        Vuetify.framework.rtl = isRTL
        state.isRTL = isRTL
    },
    setContentBoxed: (state, isBoxed) => {
        state.isContentBoxed = isBoxed
    },
    setMenuTheme: (state, theme) => {
        state.menuTheme = theme
    },
    setToolbarTheme: (state, theme) => {
        state.toolbarTheme = theme
    },
    setTimeZone: (state, zone) => {
        state.time.zone = zone
    },
    setTimeFormat: (state, format) => {
        state.time.format = format
    },
    setCurrency: (state, currency) => {
        state.currency = currency
    },
    setToolbarDetached: (state, isDetached) => {
        state.isToolbarDetached = isDetached
    },

    //foresight////////////
    setUserAdminState: (state, user) => {
        const { isSuperAdmin, permissions } = user
        state.userPermission = {
            isSuperAdmin,
            permissions
        }

        //state.userPermission = userPermission
    },
    setSelectedProject: (state, project) => {
        const { id, title, permissions } = project
        state.selectedProject = {
            id,
            title,
            permissions
        }
    },
    resetSelectedProject: (state) => {
        state.selectedProject = {
            id: '',
            title: '',
            permissions: []
        }
    },
    resetUser: (state) => {
        state.userPermission = {
            isSuperAdmin: false,
            permissions: []
        }
    },
    setPanelId(state, panelId) {
        Vue.set(state, 'panelId', panelId);
        //state.panelId = panelId;
    },
    setReportId(state, reportId) {
        Vue.set(state, 'reportId', reportId);
    },
    resetFilters(state) {
        Vue.set(state, 'filters', utilities.clone(state.initialFilters));
        //state.filters = utilities.clone(state.initialFilters);
    },
    setInitialFilters(state, { filters }) {
        Vue.set(state, 'initialFilters', filters);
        //state.initialFilters = filters;
    },
    addFilter(state, { filter }) {

       //alert(filter.column.name);
       Vue.set(filter, 'appliedValues', utilities.clone(filter.values) || []);
        Vue.set(filter, 'unappliedValues', []);

        state.filters.push(filter);
    },
    removeFilter(state, { filter }) {
        const filterIndex = state.filters.indexOf(filter);

        if (filterIndex !== -1) {
            state.filters.splice(filterIndex, 1);
        }
    },
    removeAllFilters(state) {
        Vue.set(state, 'filters', []);
        //state.filters = [];
    },
    addValue(state, { filter, value, valueType }) {
        const filterIndex = state.filters.indexOf(filter);

        if (filterIndex !== -1) {
            state.filters[filterIndex][valueType].push(value);
        }
    },
    removeValue(state, { filter, value, valueType }) {
        const filterIndex = state.filters.indexOf(filter);

        if (filterIndex !== -1) {
            const valueIndex = state.filters[filterIndex][valueType].indexOf(value);
            state.filters[filterIndex][valueType].splice(valueIndex, 1);
        }
    },
    setValues(state, { filter, values, valueType }) {
        const filterIndex = state.filters.indexOf(filter);

        if (filterIndex !== -1) {
             Vue.set(state.filters[filterIndex], valueType, values);
           // state.filters[filterIndex][valueType] = values;
        }
    },
    emptyFilterValues(state, { filter, valueType }) {
        const filterIndex = state.filters.indexOf(filter);

        if (filterIndex !== -1) {
            Vue.set(state.filters[filterIndex], valueType, []);
           // state.filters[filterIndex][valueType] = [];
        }
    },
    setFiltersState(state, filtersState) {
        Vue.set(state, 'filtersDisabled', filtersState);
       // state.filtersDisabled = filtersState;
    },
    //breadcrumbs
    setBreadCrumbItem(state, { item }) {
        state.bcItems.push(item);
    },
    deleteBreadCrumb(state) {
        state.bcItems = [{
            icon: "mdi-home",
            title: "",
            id: null
        }];
    },
    openBreadCrumbItem(state, { item }) {
        state.bcItems.splice(state.bcItems.indexOf(item) + 1);
    },
    updateFilterIsOpenedState (state, isFilterOpened) {
       state.isFilterOpened = isFilterOpened;
    },


}
